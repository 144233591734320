import React, {useState, useEffect, useLayoutEffect} from 'react';
import { StyleSheet, Text, View, TouchableOpacity , ScrollView, Image, Platform } from 'react-native';
import { useAuth, useEnv, OTTs, useSystemInfo} from '../AppContext';
import { useItemContext } from '../ItemContext';
import infoImage from '../../assets/info_right.png';
import GSS from '../GlobalStyleSheet2';
import TopHeader from '../TopHeader';
import { ProgressNext } from '../ProgressButton';
import { GetPayInfoOfDB, CheckUserItemFromDB } from '../api';
import { useModal } from '../ModalContext';
import { useFocusEffect } from '@react-navigation/native';
import { setTargetAfterLogin } from '../Global';
import { targetOS } from '../Global';
import SafetyImage from '../../assets/shield.png'

function Share1 ({navigation, route}) {
    const { isLoggedIn  } = useAuth();
    const { ENV } = useEnv();
    const {Item, setItem} = useItemContext();
    const { systemInfo }= useSystemInfo();
    let trade_charge = 550;
    const [cardName, setCardName] = useState();
    const [cardNum, setCardNum] =useState();   
    const { showModalMessage } = useModal();
    const [isCheckingCertiID, setIsCheckingCertiID] = useState(false);
    const [ monthlyPrice, setMonthlyPrice ] = useState();
    const login_before = route.params?.login_before;

    function RegisterCard() {
        let targetAfter = 'Share2';
        navigation.navigate('RegisterCardOfPayple', {targetAfterRegister: targetAfter});
    }

    function NextStep()
    {
        try {
            if(Item) {
                const newData = { ...Item };
                newData.payment_price = monthlyPrice + trade_charge;
                setItem(newData);
                //console.log(newData);
                let targetAfter = 'Share2';
                // if(newData.item_id === 1 && newData.check_new_using === true) {
                //     targetAfter = 'Share11';
                // } else {
                //     targetAfter = 'Share2';
                // }
                if( cardName && cardNum) {
                    navigation.navigate(targetAfter, {cardName, cardNum});
                } else {
                    const _message = {
                        afterOK : RegisterCard,
                        isCode : true,
                        body: () => (
                            <View>
                                <Image
                                    source={SafetyImage}
                                    style={{height:50, width:50, marginBottom:10}}
                                />
                                <Text style={GSS.mainInfoTextB }>구독을 위한 결제수단을 등록합니다. 지금 결제되지 않습니다. 등록한 카드는 언제든지 등록취소 할 수 있습니다.</Text>
                                <Text style={[GSS.textEmphasis, {fontSize:16, marginTop:5, marginBottom:5}]}>안심하고 진행 하세요. 마이샷은 어떠한 카드정보도 보유하지 않습니다.</Text>
                                <Text style={[GSS.emphasisText, {fontSize:16}]}>카드 유효성 확인을 위해 100원이 결제되나 바로 결제 취소 됩니다.</Text>
                            </View>
                        )
                    } 
                    showModalMessage(_message, 0.45);                 
                }
            }
        } catch(e)
        {
            console.error("Share1 : NextStep", e);
            const _message = {
                body : '',
            };
            if (e && e.message) {
                _message.body = `오류 : ${e.message}`;
              } else {
                // e.message가 존재하지 않는 경우, 일반적인 오류 메시지를 사용할 수 있습니다.
                _message.body = '알 수 없는 오류가 발생했습니다. 관리자에게 문의해 주세요';
              }
              showModalMessage(_message);
        }
    }
    const NextButton = () => {
        return (
            <ProgressNext
                title={'다음'}
                onPress={NextStep}
            />
        );
    }

    // useEffect(() => {
    //     console.log(login_before);
    // }, []);     
    
    useFocusEffect (
        React.useCallback(() => {
            (async () => {
                try {
                    if(isLoggedIn) {
                        const newData = { ...Item };
                        newData.user_id = ENV.user_id;
                        setItem(newData);

                        //console.log(login_before);
                        //if( true ) {
                            const result = await CheckUserItemFromDB(ENV.user_id); // await를 사용하여 비동기적으로 데이터를 저장합니다.
                            if(result.success) {
                                if( result.data.user_item_status ) {
                                    //const usings = result.data.user_item_status.split(',');
                                    if( result.data.user_item_status.includes(Item.item_name)) {
                                        const _message = {
                                            body : `${Item.display_name}은 이미 사용중입니다`
                                        }
                                        showModalMessage(_message);
                                        navigation.navigate('ShareHome');
                                    }
                                }
                            }
                        //}

                        if(Item.item_id === 1 && Item.check_new_using === true) {
                            setMonthlyPrice(Item.monthly_price2);
                        } else {
                            setMonthlyPrice(Item.monthly_price);
                        }
                        //console.log(monthlyPrice);
                        const ret = await GetPayInfoOfDB(ENV.user_id);
                        if(ret.success) {
                            if( ret.data.pay_cardname) {
                                setCardName(ret.data.pay_cardname);
                                setCardNum(ret.data.pay_cardnum);
                            }
                            if( ret.data.checked_certification === 1) {
                                setIsCheckingCertiID(true);
                            } else {
                                const targetAfterRegister = 'Share1';
                                navigation.navigate('CertifyID', {targetAfterRegister});                                
                            }
                        }                   
                  } else {
                    setTargetAfterLogin('Share1');
                    if(Platform.OS === 'web')
                    {
                        navigation.navigate('LoginWeb');
                    } else {
                        navigation.navigate('Login');
                    }                   
                  }
                } catch (e) {
                    console.error(e);
                }
          })();          
        }, [isLoggedIn, Item.monthly_price2])
    );     

    // useEffect(() => {
    //     //console.log(systemInfo);
    //     // async 함수를 정의하고 즉시 호출합니다.
    //     (async () => {
    //         try {
    //           if(isLoggedIn) {
    //                 const newData = { ...Item };
    //                 newData.user_id = ENV.user_id;
    //                 setItem(newData);
    //                 const ret = await GetPayInfoOfDB(ENV.user_id);
    //                 if(ret.success) {
    //                     if( ret.data.checked_certification === 1) {
    //                         setIsCheckingCertiID(true);
    //                     }
    //                     if( ret.data.pay_cardname) {
    //                         setCardName(ret.data.pay_cardname);
    //                         setCardNum(ret.data.pay_cardnum);
    //                     }
    //                 }                   
    //           } else {
    //               ENV.target_after_login = 'Share1';
    //               if(Platform.OS == 'web')
    //               {
    //                 navigation.navigate('LoginWeb');
    //               } else {
    //                 navigation.navigate('Login');
    //               }                   
    //           }
    //         } catch (e) {
    //           // 오류를 적절하게 핸들링합니다. (예: 로깅, 사용자에게 메시지 표시 등)
    //           console.error(e);
    //         }
    //       })(); 
    // }, [isLoggedIn]); 

    const mainViewBoxNoBorder = {
        ...GSS.mainViewBox,
        borderWidth: undefined, // 'borderWidth'를 제외합니다.
        backgroundColor: undefined
    };

    const PriceText = () => {
        if(systemInfo && systemInfo.trade_charge)
        {
            trade_charge = systemInfo.trade_charge;
        }
        if(trade_charge < 990) {
            return (
                <View>
                    <Text style={[GSS.mainDefaultText, {color: '#808080', textDecorationLine: 'line-through', textDecorationColor: '#808080'}]}>{(monthlyPrice+990).toLocaleString()}원/월</Text>
                    <Text style={GSS.mainDefaultTextB}>오픈행사가 : <Text style={[GSS.textEmphasis, {fontSize:18}]}>{(monthlyPrice+trade_charge).toLocaleString()}</Text>원/월</Text>
                </View>
            );
        } else {
            return (
                <View>
                    <Text style={GSS.mainDefaultTextB}><Text style={GSS.textEmphasis}>{(monthlyPrice+trade_charge).toLocaleString()}</Text>원/월</Text>
                </View>
            );
        }
    }

    const RegisterPay= () => {
        //navigation.navigate('RegisterCard');
        // const _message = {
        //     body : '결제수단이 정상적으로 등록되면 체크/신용카드의 유효성이 확인되는 과정 중에',
        // };
        // showModalMessage(_message); 
        const targetAfterRegister = "Share1";
        navigation.navigate('RegisterCardOfPayple', {targetAfterRegister});
    }    

    return (
        <ScrollView style={{flex:1}}>
            <View style={GSS.mainContainer}>
                <TopHeader 
                    titleImage={Platform.OS !== targetOS && OTTs[Item.ott_id].iconImage}
                    navigation={navigation}
                >
                    {Item.display_name} 파티매칭
                </TopHeader>            

                <View style={[mainViewBoxNoBorder]}>
                    <Text style={{fontWeight: 'bold', fontSize: 18,}}><Text style={GSS.textEmphasis}>{Item.display_name}</Text> 이용료 결제를 위한 카드 정보를 확인해주세요</Text>
                </View>
                <View style={[GSS.mainViewBox, {flexDirection : 'row'}]}>
                    <View><Image source={infoImage} style={{ width: 25, height: 25, marginLeft:5 }}/></View>
                    <View style={{padding: 5}}>
                        <Text style={GSS.mainDefaultText}><Text style={GSS.textEmphasis}>지금 결제되지 않으며</Text> 공유가 시작되면 결제가 진행됩니다. 계정을 <Text style={GSS.textEmphasis}>공유 받는데 문제 발생시 100% 환불</Text>해드립니다</Text>
                    </View>
                </View>
                <View style={[GSS.mainViewBox]}>
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        margin: 5
                    }}>
                        <Text style={GSS.mainDefaultText}>카드 결제일</Text>
                        <Text style={GSS.mainDefaultTextB}>공유 이용 시작일</Text>
                    </View>
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        margin: 5
                    }}>
                        <View style ={ {
                            justifyContent: 'center',
                            //alignItems: 'center',
                        }}>
                            <Text style={GSS.mainDefaultText}>결제 예정금액</Text>
                        </View>
                        <PriceText/>
                    </View>
                </View>
                <View style={[GSS.mainViewBox]}>
                    <View style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            margin:5
                    }}>
                        <Text style={GSS.mainDefaultTextB}>결제카드</Text>
                        <TouchableOpacity 
                            onPress={RegisterPay}
                        >
                            <Text style={GSS.mainDefaultTextB}>
                            { cardName ? ("변경하기") : ("등록하기")}
                            </Text>
                        </TouchableOpacity>
                    </View>
                    <View style={{margin:5}}>
                        <Text>
                        { cardName ? (`${cardName} (${cardNum})`) : ("등록되어 있는 카드가 없습니다")}
                        </Text>                    
                    </View>
                </View>
                <View style={[GSS.mainViewBox, {margin: 5, alignItems:'center', justifyContent: 'center'}]}>
                    <NextButton/>
                </View> 
            </View>

            <View style={{ justifyContent: 'center', backgroundColor: '#ffffff', padding: 10}}>
                <View style={{marginTop: 25}}>
                    <Text style={SS.aboutTitle}>안내사항</Text>
                    <Text style={SS.aboutText}>• 부가가치세가 포함된 금액입니다.</Text>
                    <Text style={SS.aboutText}>• 파티 탈퇴 전까지 매월 자동결제됩니다.</Text>
                    <Text style={SS.aboutText}>• 파티장의 과도한 부담을 방지하기 위해 파티탈퇴는 파티가입 30일 이후부터 가능합니다.</Text>
                    <Text style={SS.aboutText}>• 파타를 중도에 탈퇴하게 되는 경우 남은 일자만큼 일할 계산된 금액이 카드결제 부분취소를 통해 환불됩니다.</Text>
                    <Text style={SS.aboutText}>• 기타 자세한 사항은 문의해 주세요.</Text>
                </View>
            </View>          

        </ScrollView>
    );
};

const SS = StyleSheet.create({
    container: {
      flex: 1,
    },  
    aboutText: {
      color : '#aFaCa3',
      marginBottom: 5
    },
    aboutTitle: {
      color : '#8d969f',
      fontWeight : 'bold',
      fontSize : 16,
      marginBottom: 15
    }
  });

export default Share1;