import React, {useState, useEffect} from 'react';
import { View, Text, Image, StyleSheet, TouchableOpacity, Dimensions, Linking, ActivityIndicator} from 'react-native';
import GSS from '../GlobalStyleSheet2';
import { getOperatingSystem, setTargetAfterLogin } from '../Global';
import { useInitProc } from '../InitProc';
import { useFocusEffect, CommonActions } from '@react-navigation/native';
import { useEnv, GetOTTByName} from '../AppContext';
import { useModal } from '../ModalContext';
import { SetPromotionCode } from '../api';

const InviteProc = ({navigation, route}) => {
    let inviteCode = route.params?.invite_code ?? null;
    const [ OS, setOS] = useState();
    const { initProc} = useInitProc();
    const [ inviteInfo, setInviteInfo] = useState();
    const [ OTT, setOTT] = useState();
    const [ checkConfirms, setCheckConfirms] = useState([false, false, false]);
    const [ inviteStep, setInviteStep] = useState(0);
    const { ENV  } = useEnv();
    const { showMessage} = useModal();
    const [ buttonTitle, setButtonTitle] = useState();
    const [ textTitle, setTextTitle] = useState();   
    const [ linkStep, setLinkStep] = useState(0);

    const GetInviteCode = () => {
        return localStorage.getItem('invite_code');
    }

    const SaveInviteCode = (invite_code) => {
        localStorage.setItem('invite_code', invite_code);
    }

    const GetInviteStep = () => {
        return localStorage.getItem('invite_step');
    }

    const SaveInviteStep = (step) => {
        localStorage.setItem('invite_step', step);
        setInviteStep(step);
    }

    const LogIn = () => {
        setTargetAfterLogin('InviteProc');
        navigation.push('LoginWeb');
    }        

    useFocusEffect (
        React.useCallback(() => {
            (async () => {
                const operatingSystem = getOperatingSystem();
                setOS(operatingSystem);
    
                if( inviteCode ) {
                    //console.log('딥링크를 통해 호출 되었을때');
                    SaveInviteCode(inviteCode);
                    SaveInviteStep(0);
                } else {
                    inviteCode = GetInviteCode();
                    const step = GetInviteStep();
                    setLinkStep(step+1);
                    //console.log(step);
                } 

                if( !ENV?.user_id) {
                    LogIn();
                    return;
                }
                
                const ret = await SetPromotionCode(inviteCode, ENV.user_id);
                if( !ret.success) {
                    //console.log(ret.error);
                }

                if( operatingSystem === 'Android') {
                    const url = `https://myshott.page.link/promo?invite_code=${inviteCode}`;
                    const canOpen = await Linking.canOpenURL(url);      
                    if (canOpen) {
                        await Linking.openURL(url);
                    } else {
                        const appStoreUrl = "https://play.google.com/store/apps/details?id=com.myshott.eva";
                        Linking.openURL(appStoreUrl).catch(err => {
                            //console.error('Failed to open URL:', err);
                            navigation.dispatch(
                                CommonActions.reset({
                                    index: 0,
                                    routes: [{ 
                                        name: 'Home',
                                    }],
                                })
                            );                               
                        });
                    }
                } else {
                    navigation.dispatch(
                        CommonActions.reset({
                            index: 0,
                            routes: [{ 
                                name: 'Home',
                            }],
                        })
                    );   
                }               
          })();          
        }, [])
    );       
  
    return (
    <View style={GSS.mainContainer}>
        <View style={{flexDirection: 'row', alignItems:'center', marginTop:25, marginBottom:25}}>
            <View style={{flex:1}}>
                <Text style={SS.logoTitleText}>마이샷</Text>
            </View>
        </View>
        <View style={GSS.mainViewBox}>
            <View style={GSS.activityIndicatorContainer}>
                <ActivityIndicator size='large' color="#0000ff" />
            </View>    
        </View>       
    </View>
    );
};


const SS = StyleSheet.create({
    logoTitleText: {
    textAlign: 'center',
    fontSize: 40,
    color: '#00e5e0',
    fontFamily: 'Gaegu'
    },
    loginImage: {
        width: 30, // 이미지 너비
        height: 30, // 이미지 높이
        borderRadius: 15,
        backgroundColor: '#291B44',
        padding: 5,
        marginRight: 5,
    },
    confirmView: {
        //flex: 
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems : 'center',
    },     
});

export default InviteProc;