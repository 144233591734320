import React, {useState, useEffect} from 'react';
import { View, Platform, BackHandler, ActivityIndicator } from 'react-native';
import { useIsFocused, useFocusEffect, useNavigationState } from '@react-navigation/native';
import { useAuth, useEnv } from '../AppContext';
import MyInfoHomeOfLogin from './MyInfoHomeOfLogin';
import GSS from '../GlobalStyleSheet2';
import LoginWeb from '../LoginWeb';
import Login from '../Login';
import { setTargetAfterLogin } from '../Global';
import { Helmet } from 'react-helmet-async';

function MyInfoHome ({navigation, route}) {
  const { isLoggedIn, logout } = useAuth();
  const { ENV, setEnv } = useEnv();
  const isFocused = useIsFocused();
  const tabState = useNavigationState(state => state);

  if(Platform.OS !== 'web') {
    useFocusEffect(
      React.useCallback(() => {
          const onBackPress = () => {
              navigation.reset({
                  index: 0,
                  routes: [{ name: 'home' }],
              });            
              return true;
          };
          BackHandler.addEventListener('hardwareBackPress', onBackPress);
          return () => {
              BackHandler.removeEventListener('hardwareBackPress', onBackPress);
          };
      }, [])
    );       
  }

  // useFocusEffect(
  //   React.useCallback(() => {
  //     console.log('활성화 됨');
  //     return () => {
  //       // 탭이 포커스를 잃을 때 수행할 동작 (옵션)
  //     };
  //   }, [])
  // );  

  // useEffect(() => {
  //   const focusListener = navigation.addListener('focus', () => {
  //     console.log('활성화 됨 0');
  //   });

  //   return focusListener;
  // }, [navigation]);  

  if(Platform.OS !== 'web') {
    useFocusEffect(
      React.useCallback(() => {
      if(ENV.user_id === null) {
        LoginStep();
      }
      }, [ENV.user_id])
    );   
  }
      
  function LoginStep()
  {
      setTargetAfterLogin('MyInfoHome');
      navigation.navigate('Login');
  }  

  const DisplayContent = () => {
    if(isLoggedIn) {
      return (
       <MyInfoHomeOfLogin
        navigation = {navigation}
        route = {route}
       />
      );
    } else {
      return (
        <LoginWeb
          navigation = {navigation}
          route = {route}
          target_after_login2 = {'MyInfoHome'}
        />
      );
    }    
  }   

  if( Platform.OS === 'web' ) {
    return (
      <React.Fragment>
        <Helmet>
          <title>마이샷 - 넷플릭스,티빙,웨이브,디지니+ OTT계정공유</title>
          <meta name="description" content="마이샷 계정관리, 정산계좌 변경, 결제정보 변경, 관리자 문의" />
          <meta property="og:description" content="마이샷 계정관리, 정산계좌 변경, 결제정보 변경, 관리자 문의" />
          <meta property="og:image" content="https://myshott.co.kr/og_image.png" />
          <meta property="og:title" content="마이샷 내정보 관리" />
        </Helmet>       
        <View style={{flex:1}}>
          <DisplayContent/>
        </View> 
      </React.Fragment>
    );
  } else {
    return (
      <View style={{flex: 1}}>
        {isLoggedIn && (
          <MyInfoHomeOfLogin navigation={navigation} route={route} />
        )}
      </View>
    ); 
  }
};

export default MyInfoHome;