import React, { useState,  } from 'react';
import { Modal, View, StyleSheet, Dimensions, Text, ScrollView, Platform, ActivityIndicator } from 'react-native';
import ProgressButton from './ProgressButton';
import GSS from './GlobalStyleSheet2';
import { getOperatingSystem } from './Global';

const MessageModalContext = React.createContext();

export const ModalProvider = ({ children }) => {
  const [modals, setModals] = useState([]);
  const [modalContent, setModalContent] = useState(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [heightPercentage, setHeightPercentage] = useState(0.4);
  const [widthPercentage, setWidthPercentage] = useState(1);
  const [animationType, setAnimationType] = useState('slide');
  const [customModalStyle, setCustomModalStyle] = useState({});
  const [isModalCentered, setIsModalCentered] = useState(false);

  const windowHeight = Dimensions.get('window').height;
  //const windowWidth = Platform.OS==='web' ? 800 : Dimensions.get('window').width;
  //const windowWidth = Dimensions.get('window').width;
  let windowWidth;
  if( Platform.OS==='web' && getOperatingSystem() === 'Windows' ) {
    windowWidth = 800;
  } else {
    windowWidth = Dimensions.get('window').width;
  }

  const openModal = (content, heightPercentage = 0.4, widthPercentage = 1, animation = '', style = {}, centered = false, modalId = null) => {
    let _modalId;
    if( modalId ) {
      _modalId = modalId;
    } else {
      _modalId = Date.now().toString(); // 고유한 ID 생성
    } 
    const newModal = {
      modalId : _modalId,
      content,
      heightPercentage,
      widthPercentage,
      animation,
      style,
      centered
    };
    setModals(prevModals => [...prevModals, newModal]);
    return _modalId;
  };
 
  const closeModal = (modalId) => {
    setModals(prevModals => prevModals.filter(modal => modal.modalId !== modalId));
  };

  const dynamicModalSize = isModalCentered 
  ? {
    height: windowHeight * heightPercentage,
    width: windowWidth * widthPercentage,
    alignSelf: 'center',
  } 
  : {
    height: windowHeight * heightPercentage,
    width: windowWidth,
  };

  const styles = StyleSheet.create({
    modalOverlay: {
      flex: 1,
      justifyContent: 'center', // 중앙 정렬을 위해 'center'로 변경
      alignItems: 'center', // 중앙 정렬을 위해 추가
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
      backgroundColor: '#F1F1F1',
      padding: 5,
      borderRadius: 10,
      ...customModalStyle, // 사용자 지정 스타일 추가
    },
    modal: {
      justifyContent: 'flex-end',
      margin: 0,
    },
  });

  const ShowBody = ({messages}) => {
    if (typeof messages === 'string') {
      return(
        <Text style={GSS.messageText}>{messages}</Text>
      );
    } else if (Array.isArray(messages)) {
      return messages.map((message, index) => (
        <Text key={index} style={GSS.messageText}>{message}</Text>
      ));
    }
  }

  const _ShowModal = ({_message, closeModal }) => {
    //console.log('ShowModalMessage : ', _message);
    const isCode = _message.isCode ? true : false;
    const confirmMessage = async () => {
        try {
          closeModal(_message.modalId);
          const { afterOK } = _message;
          if (afterOK && typeof afterOK === 'function') {
            afterOK();
          }
        } catch (error) {
          console.error("ShowModalMessage error:", error);
        }
    };
  
    return (
        <View style={{ flex: 1, padding: 5, margin:5}}>
            <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
              <View>
                <Text style={GSS.mainTitleText}>{_message.title ? _message.title : ''}</Text>
              </View>
            </View>
            { isCode ? (
              <ScrollView style = {{flex:5, marginTop:5}}>
                {_message.body()}
              </ScrollView>
            ) : (
              <ScrollView style = {{flex:5, marginTop:5}}>
                <ShowBody
                  messages={_message.body}
                />
              </ScrollView>
            )}
            <View style={{alignItems:'center', marginTop:10}}>
                <ProgressButton
                    title ='확인'
                    style = {{backgroundColor:'#1e1f31'}}
                    textStyle = {{color:'#FFFFFF'}}
                    onPress={confirmMessage}                
                />
            </View>
        </View>
    );
  };  

// const _message = {
//   title : title,
//   body : body,
//   afterOK : afterOK
// }
  const showModalMessage = (_message, height=0.4) => {
    return openModal(
      <_ShowModal
          _message = {_message}
          closeModal={(modalId) => closeModal(modalId)} 
      /> ,
      height,    // heightPercentage, 필요한 경우 다른 값으로 설정
      0.85,   // widthPercentage
      'slide', // animation, 필요한 경우 다른 값으로 설정
      {},      // style, 필요한 경우 스타일 객체로 설정
      true     // centered, 모달을 중앙에 배치하려면 true로 설정                
    );
  };

  const showMessage = (message) => {
    const _message = {
      body : message
    }
    showModalMessage(_message);
  }

  const _confirmModal = ({_confirm, closeModal}) => {
    const noTitle = _confirm.noTitle ? _confirm.noTitle : '취소';
    const yesTitle = _confirm.yesTitle ? _confirm.yesTitle : '확인';
    const isCode = _confirm.isCode ? true : false;
    //console.log('ShowModalMessage : ', _confirm);
    const confirmMessage = (isOK) => {
        try {
            closeModal(_confirm.modalId);
            if( isOK ) {
              const { afterOK } = _confirm;
              if (afterOK && typeof afterOK === 'function') {
                afterOK();
              }
            } else {
              const { afterCancel } = _confirm;
              if (afterCancel && typeof afterCancel === 'function') {
                afterCancel();
              }

            }
        } catch (error) {
            console.error("ShowModalMessage error:", error);
        }
    };
  
    return (
        <View style={{ flex: 1, padding: 5, margin:5}}>
            <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
              <View>
                <Text style={GSS.mainTitleText}>{_confirm?.title ? _confirm.title : ''}</Text>
              </View>
            </View>
              { isCode ? (
                <ScrollView style = {{flex:5, marginTop:5}}>
                {_confirm.body()}
                </ScrollView>
              ) : (
                <ScrollView style = {{flex:5, marginTop:5}}>
                  <Text style={[GSS.messageText, {marginLeft:10}]}>{_confirm.body}</Text>
                </ScrollView>
              )}
            <View style={{flexDirection:'row',alignItems:'center', justifyContent:'center', marginTop:10}}>
                <ProgressButton
                    title = {noTitle}
                    style = {{backgroundColor:'#dbdcf0'}}
                    textStyle = {{color:'#1d1f30'}}
                    onPress={() => confirmMessage(false)}                
                />
                <View style= {{width:15}}/>
                <ProgressButton
                    title = {yesTitle}
                    style = {{backgroundColor:'#353656'}}
                    textStyle = {{color:'#FFFFFF'}}
                    onPress={() => confirmMessage(true)}                
                />                
            </View>
        </View>
    );
  };    

  const confirmModal = (_confirm) => {
    return openModal(
      <_confirmModal
        _confirm = {_confirm}
        closeModal={(modalId) => closeModal(modalId)} 
      /> ,
      0.4,    // heightPercentage, 필요한 경우 다른 값으로 설정
      0.85,   // widthPercentage
      'slide', // animation, 필요한 경우 다른 값으로 설정
      {},      // style, 필요한 경우 스타일 객체로 설정
      true     // centered, 모달을 중앙에 배치하려면 true로 설정                
    );
  };

  const _WaitModal = ({message, closeModal }) => {
    return (
        <View style={{ flex: 1, padding: 5, margin:5}}>
            <View style={{marginBottom:25}}>
              <View>
                <Text style={GSS.mainDefaultText}>{message}</Text>
              </View>
            </View>
            <View style={GSS.activityIndicatorContainer}>
              <ActivityIndicator size='large' color="#0000ff" />
            </View>
        </View>
    );
  };  

  const waitMessage = (message) => {
    return openModal(
      <_WaitModal
          message = {message}
          closeModal={(modalId) => closeModal(modalId)} 
      /> ,
      0.25,    // heightPercentage, 필요한 경우 다른 값으로 설정
      0.85,   // widthPercentage
      'slide', // animation, 필요한 경우 다른 값으로 설정
      {},      // style, 필요한 경우 스타일 객체로 설정
      true     // centered, 모달을 중앙에 배치하려면 true로 설정                
    );
  };


  return (
    <MessageModalContext.Provider value={{ openModal, closeModal, showModalMessage, confirmModal, showMessage, waitMessage }}>
      {children}
      {
        modals.map(({ modalId, content, heightPercentage, widthPercentage, animation, style, centered }) => (
          <Modal
            key={modalId}
            modalId={modalId}
            transparent
            visible={true}
            onRequestClose={() => closeModal(modalId)}
            animationType={animation}
            style={styles.modal}
            closeModal={() => closeModal(modalId)}
          >
            <View style={styles.modalOverlay}>
              <View style={[styles.modalContent, {height: windowHeight * heightPercentage,
                width: windowWidth * widthPercentage}, style]}>
                {React.cloneElement(content, { closeModal: () => closeModal(modalId) })}
              </View>
            </View>
          </Modal>
        ))
    }
    </MessageModalContext.Provider>
  );
};
export function useModal() {
    return React.useContext(MessageModalContext);
}

// 사용 예>

// import { ModalProvider } from './ModalContext';

// const App = () => {
//   return (
//     <ModalProvider>
//       {/* 나머지 컴포넌트들 */}
//     </ModalProvider>
//   );
// };

// import { useModal } from './ModalContext';

// const SomeScreen = () => {
//   const { openModal, closeModal } = useModal();

//   const handleOpenModal = () => {
//     openModal(
//       <View>
//         {/* 여기에 모달 컨텐츠를 넣습니다. */}
//         <Button title="Close Modal" onPress={closeModal} />
//       </View>
//     );
//   };

//   return (
//     <View>
//       <Button title="Open Modal" onPress={handleOpenModal} />
//     </View>
//   );
// };


