import React, {useState, useEffect, useCallback } from 'react';
import { 
  Text, View, Image, TextInput, Platform,
} from 'react-native';
import { useAuth, useEnv, OTTs} from '../AppContext';
import { useItemContext } from '../ItemContext';
import infoImage from '../../assets/info_right.png';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import GSS from '../GlobalStyleSheet2';
import TopHeader from '../TopHeader';
import { ProgressNext } from '../ProgressButton';
import { targetOS } from '../Global';

function ManagerShare2 ({navigation, route}) {
  const { isLoggedIn  } = useAuth();
  const { ENV } = useEnv();
  const {Item, setItem} = useItemContext();
  const [checkCompleted, setCheckCompleted] = useState(false);
  // 동적으로 여러 개의 입력값을 관리하기 위한 상태
  const [inputValues, setInputValues] = useState([]); // 초기 상태에 따라 TextInput의 수가 정해집니다.
  let numberOfTextInput = 1;

  function NextStep()
  {
    const nick_name = inputValues.join('|||');
    //console.log(nick_name);
    const newData = { ...Item }; // 먼저 Item을 펼칩니다.
    newData.nick_name = nick_name;
    setItem(newData);    
    navigation.navigate('ManagerShare3');
  }

  useEffect(() => {
    if(!Item.item_id) return null;
    const initialize = async () => {
      try {
        if (!isLoggedIn) {
          ENV.target_after_login = 'ManagerShare1';
          if (Platform.OS === 'web') {
            navigation.navigate('LoginWeb');
          } else {
            navigation.navigate('Login');
          }
        }

        const calculatedNumberOfTextInput = 4 - Item.maximum_usage;
        // 새 배열 생성 로직은 상태 업데이트 함수 내부에서 처리합니다.
        setInputValues(prevValues => {
          // 이전 값이 이미 적절한 길이를 가지고 있다면, 새 배열을 생성하지 않습니다.
          if (prevValues.length === calculatedNumberOfTextInput) {
            return prevValues;
          }
          // 새로운 배열 생성
          return new Array(calculatedNumberOfTextInput).fill('');
        });        
  
        // 모든 입력 필드가 비어 있지 않은지 확인합니다.
        const allFilled = inputValues.every(item => item.trim() !== '');
        setCheckCompleted(allFilled);
      } catch (error) {
        console.error(error);
        // 오류 발생 시 사용자에게 알리기 (예: 알림, 토스트 메시지 등)
        alert('오류가 발생했습니다. 다시 시도해주세요.'); // 여기서 'alert'은 간단한 예시입니다. 실제 환경에선 사용자에게 더 나은 피드백을 제공해야 합니다.
      }
    };
  
    initialize();
  }, [isLoggedIn, navigation, inputValues]);

  const NextButton = () => {
    return (
      <ProgressNext
        title={'다음'}
        onPress={ checkCompleted ? NextStep : null}
        disabled={checkCompleted ? false : true}
      />
    );
  }  

  const InputField = React.memo(({ initialValue, onBlur, index }) => {
    const [localValue, setLocalValue] = useState(initialValue);
  
    useEffect(() => {
      setLocalValue(initialValue);  // 외부에서 전달된 값으로 로컬 상태를 초기화합니다.
    }, [initialValue]);
  
    const handleTextChange = (input) => {
      // 공백이 포함되어 있으면 입력을 업데이트하지 않음
      if (input.includes(' ')) {
        return;
      }      
      setLocalValue(input);  // 사용자 입력으로 로컬 상태를 업데이트합니다.
    };
  
    const handleBlurEvent = () => {
      onBlur(localValue, index);  // 포커스가 사라질 때 변경 사항을 외부로 전달합니다.
    };
  
    return (
      <View style={GSS.textInputContainer}>
        <Text style={GSS.textInputTile}>{index+1}. </Text>
        <TextInput
          style={GSS.textInputText}
          maxLength={6}
          placeholder={`프로필의 닉네임을 입력하세요`}
          value={localValue}
          onChangeText={handleTextChange}
          onBlur={handleBlurEvent}  // 사용자가 이 필드에서 벗어났을 때 handleBlurEvent 호출
        />
      </View>
    );
  });

  const handleBlur = useCallback((text, index) => {
    setInputValues((prevValues) => {
      const newInputValues = [...prevValues];
      newInputValues[index] = text; // 특정 인덱스의 값을 업데이트합니다.
      return newInputValues;
    });
  }, []);

  const renderInputFields = () => {
    return inputValues.map((value, index) => (
      <InputField 
        key={index}
        initialValue={value} 
        index={index} 
        onBlur={handleBlur}  // onBlur 이벤트 핸들러를 props로 전달합니다.
      />
    ));
  };

  const renderProfile = () => {
    return inputValues.map((value, index) => (
      <View key={index} style={{alignItems: 'center', margin:15}}>
        <Image source={OTTs[Item.ott_id].profileImage} style={{ width: 50, height: 50, marginBottom: 5}}></Image>
        <Text>{value ? value : index+1}</Text>
      </View>
    ));
  };  

  return (
    <KeyboardAwareScrollView
      style={{ flex: 1 }}
      resetScrollToCoords={{ x: 0, y: 0 }}  // 필요한 경우 스크롤 위치 조정
      contentContainerStyle={{ flexGrow: 1 }}  // 컨텐츠 스타일 조정
      scrollEnabled={true}  // 필요에 따라 스크롤 비활성화
    >
        <View style={GSS.mainContainer}>
            {/* <View style={[mainViewBoxNoBorder]}>
              <Text style={{fontWeight: 'bold', fontSize: 22,}}><Text style={GSS.textEmphasis}>넷플릭스</Text>에서 사용하고 있는 나의 프로필 닉네임을 지정합니다</Text>
            </View> */}
          <TopHeader 
              titleImage={Platform.OS !== targetOS && OTTs[Item.ott_id].iconImage}
              navigation={navigation}
          >
              {Item.display_name} 나의 프로필 설정
          </TopHeader>              
          <View style={[GSS.mainViewBox, {flex:0.8, flexDirection : 'row', justifyContent:'center', alignItems:'center'}]}>
            <View><Image source={infoImage} style={{ width: 25, height: 25, marginLeft:5 }}/></View>
            <View style={{padding: 5}}>
              { Item.item_id===1 && (
                <Text style={GSS.mainDefaultText}>• {Item.display_name}의 <Text style={GSS.textEmphasis}>일반회원</Text> 모집을 위한 설정입니다.</Text>
              )}
              <Text style={GSS.mainDefaultText}>• {Item.display_name}에서 <Text style={GSS.textEmphasis}>사용하는 닉네임</Text>으로 설정해주세요.</Text>
              <Text style={GSS.mainDefaultText}>• 닉네임이 다르면 파티원이 내 프로필을 <Text style={GSS.textEmphasisAlert}>이용•삭제</Text>할 수도 있습니다.</Text>
              <Text style={GSS.mainDefaultText}>• 프로필의 닉네임은 최대 6자리까지 가능합니다.</Text>
            </View>
          </View>
          <View style={[GSS.mainViewBox, {flex:1, justifyContent: 'center', alignItems: 'center'}]}>
            <View>
                <Text style={GSS.mainDefaultText}>현재 사용하고 있는 프로필</Text>
            </View>
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              {renderProfile()}
            </View>
          </View>
          <View style={[GSS.mainViewBox, {flex:1}]}>
            {renderInputFields()}
          </View>
          <View style={[GSS.mainViewBox, {flex:1, margin: 5, alignItems:'center', justifyContent: 'center'}]}>
            <NextButton/>
          </View> 
        </View>  
    </KeyboardAwareScrollView>
  );
};

export default ManagerShare2;