import Axios from 'axios';
import { routerServer } from './Global.js';

const fetchDataFromDB = async (url) => {
  try {
    const response = await Axios.get(url);
    if (response.status === 200) {
      return { success: true, data: response.data };
    } else {
      return { success: false, error: response.data };
    }
  } catch (error) {
    // 네트워크 오류나 서버에서 오류 응답을 보낸 경우
    if (error.response) {
      // 서버가 응답한 경우 (예: HTTP 상태 코드 400, 500)
      return { success: false, error: error.response.data };
    } else if (error.request) {
      // 요청이 이루어졌으나 응답을 받지 못한 경우
      return { success: false, error: "No response received from server" };
    } else {
      // 요청을 만드는 중 오류 발생
      return { success: false, error: error.toString() };
    }
  }
};

const saveDataToDB = async (url, saveData) => {
  try {
    const response = await Axios.post(url, saveData);
    if (response.status === 201) {
      return { success: true, data: response.data };
    } else {
      return { success: false, error: response.data };
    }
  } catch (error) {
    // 네트워크 오류나 서버에서 오류 응답을 보낸 경우
    if (error.response) {
      // 서버가 응답한 경우 (예: HTTP 상태 코드 400, 500)
      return { success: false, error: error.response.data };
    } else if (error.request) {
      // 요청이 이루어졌으나 응답을 받지 못한 경우
      return { success: false, error: "No response received from server" };
    } else {
      // 요청을 만드는 중 오류 발생
      return { success: false, error: error.toString() };
    }
  }
};

export const ReadItemFromDB = async (item_name) => {
    try {
        const url = `${routerServer}/read_item/${item_name}`;
        //console.log(url);
        const response = await Axios.get(url);
        if (response.status === 200) {
          return { success: true, data: response.data };
        } else {
          // 예상치 못한 상태 코드의 경우, 오류 정보를 반환
          return { success: false, error: `Unexpected response code: ${response.status}` };
        }
      } catch (error) {
        //const errorMsg = "ReadItem :" + error.toString();
        // 네트워크 오류 또는 서버 오류로 인한 문제, 오류 정보를 반환
        return { success: false, error: error.toString() };
      }
};

export const SaveItemToDB = async (curItem) => {
    const url = `${routerServer}/db/save_useritem`;
    //console.log(url);
    return await saveDataToDB(url, curItem);        
};

export const ReadUserItemFromDB = async (user_id) => {
    const url = `${routerServer}/db/read_user_item/${user_id}`;
    return await fetchDataFromDB(url); 
};

export const SaveWaitingToDB = async (curItem) => {
    const url = `${routerServer}/db/save_waiting`;
    //console.log(url);
    return await saveDataToDB(url, curItem);      
};

export const CheckUserItemFromDB = async (user_id) => {
    const url = `${routerServer}/db/check_user_item/${user_id}`;
    //console.log(url);
    return await fetchDataFromDB(url);    
};

export const CancelWaitingFromDB = async (waiting_id) => {
  const url = `${routerServer}/db/cancel_waiting/${waiting_id}`;
  //console.log(url);
  return await fetchDataFromDB(url);
};

export const ImmediatelyMatchingFromDB = async (waiting_id) => {
  const url = `${routerServer}/db/immediately_matching/${waiting_id}`;
  //console.log(url);
  return await fetchDataFromDB(url);
};

export const SaveNickNameToDB = async (used_item_id, nick_name) => {
  const postData = {
    used_item_id : used_item_id,
    nick_name : nick_name
  };
  const url = `${routerServer}/db/save_nick_name`;
  //console.log(url);
  return await saveDataToDB(url, postData);      
};

export const GetIdPwdFromDB = async (user_item_id) => {
  const url = `${routerServer}/db/get_id_pwd/${user_item_id}`;
  //console.log(url);
  return await fetchDataFromDB(url);
};

export const DeleteUsedItemToDB = async (used_item_id) => {
  const url = `${routerServer}/db/delete_useditem/${used_item_id}`;
  //console.log(url);
  return await fetchDataFromDB(url);
};

export const SendUserMessageToDB = async (used_item, message) => {
  const postData = {
    used_item : used_item,
    message : message
  };
  const url = `${routerServer}/db/send_user_message`;
  //console.log(url);
  return await saveDataToDB(url, postData);      
};

export const SaveHostNickNamesToDB = async (user_item) => {
  const url = `${routerServer}/db/save_host_nicknames`;
  //console.log(url);
  return await saveDataToDB(url, user_item);      
};

export const SendHostMessageToDB = async (user_item, nick_name, message) => {
  const postData = {
    user_item : user_item,
    nick_name : nick_name,
    message : message
  };
  const url = `${routerServer}/db/send_host_message`;
  //console.log(url);
  return await saveDataToDB(url, postData);      
};

export const DeleteUserItemToDB = async (user_item_id) => {
  const url = `${routerServer}/db/delete_useritem/${user_item_id}`;
  //console.log(url);
  return await fetchDataFromDB(url);
};

export const ChangeOTTAccountToDB = async (user_item_id, account_userid, account_pwd) => {
  const postData = {
    user_item_id : user_item_id,
    account_userid : account_userid,
    account_pwd : account_pwd
  };
  const url = `${routerServer}/db/chanage_ott_account`;
  //console.log(url);
  return await saveDataToDB(url, postData);      
};

export const RegisterPushNotiToDB = async (user_id, token) => {
  const postData = {
    user_id : user_id,
    token_push_noti : token
  };
  const url = `${routerServer}/db/register_push_noti`;
  //console.log(url);
  return await saveDataToDB(url, postData);      
};

export const UpdateUserMessageToDB = async (message_ids) => {
  const postData = {
    message_ids : message_ids
  };
  const url = `${routerServer}/db/update_user_message`;
  //console.log(url);
  return await saveDataToDB(url, postData);      
};

export const GetUserMessagesFromDB = async (user_id) => {
  const url = `${routerServer}/db/get_user_messages/${user_id}`;
  //console.log(url);
  return await fetchDataFromDB(url);
};

export const GetSysMessagesFromDB = async (user_id) => {
  const url = `${routerServer}/db/get_sys_messages/${user_id}`;
  //console.log(url);
  return await fetchDataFromDB(url);
};

export const AdminQnAToDB = async (user_id, message) => {
  const postData = {
    user_id : user_id,
    message : message
  };
  const url = `${routerServer}/db/admin_qna`;
  //console.log(url);
  return await saveDataToDB(url, postData);      
};

export const GetAccountInfoFromDB = async (user_id) => {
  const url = `${routerServer}/db/get_account_info/${user_id}`;
  //console.log(url);
  return await fetchDataFromDB(url);
};

export const SaveAccountToDB = async (user_id, phone_number) => {
  const postData = {
    user_id : user_id,
    phone_number : phone_number
  };
  const url = `${routerServer}/db/save_account_info`;
  //console.log(url);
  return await saveDataToDB(url, postData);      
};

export const DeletePhoneNumberOfDB = async (user_id) => {
  const url = `${routerServer}/db/delete_phone_number/${user_id}`;
  //console.log(url);
  return await fetchDataFromDB(url);
};

export const DeleteIDOfDB = async (user_id) => {
  const url = `${routerServer}/db/delete_user_id/${user_id}`;
  //console.log(url);
  return await fetchDataFromDB(url);
};

export const SaveBankAccountToDB = async (user_id, bank_id, bank_number, bank_code) => {
  const postData = {
    user_id : user_id,
    bank_id : bank_id,
    bank_code : bank_code,
    bank_number : bank_number
  };
  //const url = `${routerServer}/db/save_bank_account`;
  const url = 'https://myshott.com/db/save_bank_account';
  //console.log(url);
  return await saveDataToDB(url, postData);      
};

export const DeleteBankAccountOfDB = async (user_id) => {
  const url = `${routerServer}/db/delete_bank_account/${user_id}`;
  //console.log(url);
  return await fetchDataFromDB(url);
};

export const GetPayInfoOfDB = async (user_id) => {
  const url = `${routerServer}/db/get_payifno/${user_id}`;
  //console.log(url);
  return await fetchDataFromDB(url);
};

export const DeletePayInfoOfDB = async (user_id) => {
  const url = `${routerServer}/payple/delete_card/${user_id}`;
  //console.log(url);
  return await fetchDataFromDB(url);
};

export const CheckAccountToDB = async (bankInfo) => {
  //const postData = { ...bankInfo  };
  const url = `${routerServer}/payple/check_account`;
  //console.log(url);
  return await saveDataToDB(url, bankInfo);      
};

export const SaveCertiIDToDB = async (ok_data, user_id, inviter_code) => {
  const postData = {
    user_id : user_id,
    certi_data : {
      user_name : ok_data.user_name,
      phone_number : ok_data.phone_number,
      age : ok_data.age
    },
    inviter_code : inviter_code,
    certi_type : ok_data.ok_type
  };  
  if(ok_data.gender) {
    postData.certi_data.gender = ok_data.gender;
  }
  const url = `${routerServer}/db/save_certi_id`;
  //console.log(url);
  return await saveDataToDB(url, postData);      
};

export const CheckCertiIDOfDB = async (user_id) => {
  const url = `${routerServer}/db/check_certi_id/${user_id}`;
  //console.log(url);
  return await fetchDataFromDB(url);
};

export const NextflixMatchedToDB = async (user_item, accountIndex) => {
  const postData = {
    user_item : user_item,
    accountIndex : accountIndex
  };
  const url = `${routerServer}/db/netflix_matched`;
  //console.log(url);
  return await saveDataToDB(url, postData);      
};

export const CheckUserAccountToDB = async (used_item_id) => {
  const url = `${routerServer}/db/check_user_account/${used_item_id}`;
  //console.log(url);
  return await fetchDataFromDB(url);
};

// 이메일을 생성 또는 만들어진 이메일 요청 갯수만큼 얻어온다
export const GetAccountEmailofDB = async (user_id, request_count, user_item_id=null) => {
  let url;
  if( user_item_id) 
    url = `${routerServer}/db/create_email_account/${user_id}/${request_count}/${user_item_id}`;
  else 
    url = `${routerServer}/db/create_email_account/${user_id}/${request_count}/-1`;
  //console.log(url);
  return await fetchDataFromDB(url);
};

// t_account_email 의 레코드값을 읽는다
export const GetAccountEmail = async (account_id) => {
  const url = `${routerServer}/db/get_account_email/${account_id}`;
  //console.log(url);
  return await fetchDataFromDB(url);
};

// t_email 의 레코드값을 읽는다
export const GetEmail = async (email_id) => {
  const url = `${routerServer}/postfix/get_email/${email_id}`;
  //console.log(url);
  return await fetchDataFromDB(url);
};

// t_email 의 레코드값을 읽는다, 현 t_email_account의 email_id가 업데이트 된 경우만을...
export const GetNewEmail = async (email, email_id) => {
  const url = `${routerServer}/postfix/get_new_email/${email}/${email_id}`;
  //console.log(url);
  return await fetchDataFromDB(url);
};

// t_email 의 마지막 email_id와 t_email_account의 email_id를 일치시킨다
export const SetSyncEmailID = async (account_id) => {
  const url = `${routerServer}/postfix/set_sync_email/${account_id}`;
  //console.log(url);
  return await fetchDataFromDB(url);
};

export const SetEmailPassword = async (account_id, password) => {
  const postData = {
    account_id : account_id,
    pwd : password
  };
  const url = `${routerServer}/db/set_account_password`;
  //console.log(url);
  return await saveDataToDB(url, postData);      
};

// OTT 계정을 위한 마이샷 이메일 계정을 생성
export const GetAccountEmailofDB2 = async (user_id, item_id, user_item_id) => {
  let url = `${routerServer}/db/create_email_account2/${user_id}/${item_id}/${user_item_id}`;;
  //console.log(url);
  return await fetchDataFromDB(url);
};

// t_email 의 마지막 email_id와 t_myaccount의 email_id를 일치시킨다
export const SetSyncEmailID2 = async (myaccount_id) => {
  const url = `${routerServer}/postfix/set_sync_email2/${myaccount_id}`;
  //console.log(url);
  return await fetchDataFromDB(url);
};

export const ChangeUserAccount = async (user_item_id, account_userid) => {
  const postData = {
    user_item_id : user_item_id,
    account_userid : account_userid
  };
  const url = `${routerServer}/db/change_useritem_account`;
  //console.log(url);
  return await saveDataToDB(url, postData);      
};

// OTT 계정을 위한 마이샷 이메일 계정 얻기
export const GetMyAccountofDB = async (user_item_id) => {
  let url = `${routerServer}/db/get_myaccount/${user_item_id}`;;
  //console.log(url);
  return await fetchDataFromDB(url);
};

// 마이샷 이메일 내용 얻기
export const GetEmailContentofDB = async (email, count) => {
  let url = `${routerServer}/postfix/get_email_content/${email}/${count}`;;
  //console.log(url);
  return await fetchDataFromDB(url);
};

// 넷플릭스 이메일 공유 중지
export const DeleteAccountEmail = async (item_id, user_item_id, account_id) => {
  const postData = {
    item_id : item_id,
    user_item_id : user_item_id,
    account_id : account_id
  };
  const url = `${routerServer}/db/delete_account_email`;
  //console.log(url);
  return await saveDataToDB(url, postData);      
};

// 공유 계정에 대한 정산 정보 얻기
export const GetAccountofDB = async (user_item_id) => {
  let url = `${routerServer}/db/get_account/${user_item_id}`;;
  //console.log(url);
  return await fetchDataFromDB(url);
};

// 파티장 관리 오류 목록 얻기
export const GetErrorType = async () => {
  let url = `${routerServer}/db/get_error_type`;;
  //console.log(url);
  return await fetchDataFromDB(url);
};

// 파티장 오류 내용 신고
export const saveNotifyError = async (notifyContent) => {
  const postData = {
    user_item_id : notifyContent.user_item_id,
    used_item_id : notifyContent.used_item_id,
    user_id : notifyContent.user_id, 
    error_type : notifyContent.error_type,
    error_desc : notifyContent.error_des
  };
  const url = `${routerServer}/db/notify_error`;
  //console.log(url);
  return await saveDataToDB(url, postData);      
};

// 파티장 오류 내용 얻기
export const GetNotifyError = async (user_item_id) => {
  let url = `${routerServer}/db/get_notify_error/${user_item_id}`;;
  //console.log(url);
  return await fetchDataFromDB(url);
};

// 파티장 오류 내용 얻기
export const SetNotifyError = async (error_id) => {
  let url = `${routerServer}/db/set_notify_error/${error_id}`;;
  //console.log(url);
  return await fetchDataFromDB(url);
};

// 링크코드(link_code)로 파티 정보를 얻는다
export const GetLinkInfo = async (link_code) => {
  let url = `${routerServer}/db/get_link_info/${link_code}`;;
  //console.log(url);
  return await fetchDataFromDB(url);
};

// 링크코드(link_code)로 파티 가입을 시킨다
export const SetLinkInfo = async (link_code, user_id) => {
  const postData = {
    link_code : link_code,
    user_id : user_id,
  };
  const url = `${routerServer}/db/set_link_info`;
  //console.log(url);
  return await saveDataToDB(url, postData);      
};

// 현재 파티에서 사용중인 닉네임을 확인한다
export const GetPatryNicknams = async (user_item_id) => {
  let url = `${routerServer}/db/get_party_nicks/${user_item_id}`;;
  //console.log(url);
  return await fetchDataFromDB(url);
};

// 앱 기기일때 디바이스정보를 저장한다
export const SetDeviceInfo = async (device_info, user_id) => {
  const postData = {
    device_info : device_info,
    user_id : user_id,
  };
  const url = `${routerServer}/db/set_device_info`;
  //console.log(url);
  return await saveDataToDB(url, postData);      
};

// 앱이 설치 되어 있는가( 웹 기기에 디바이스정보가 저장되어 있는가로 대처)
export const CheckInstallled = async (user_id) => {
  let url = `${routerServer}/db/check_installed/${user_id}`;;
  //console.log(url);
  return await fetchDataFromDB(url);
};

// 앱이 시작될때 실행할 작업을 등록한다
export const RegisterTodo = async (user_id, todo_type, todo_param, only_app) => {
  const postData = {
    user_id : user_id,
    todo_type : todo_type,
    todo_param : todo_param,
    only_app : only_app
  };
  const url = `${routerServer}/db/register_todo`;
  //console.log(url);
  return await saveDataToDB(url, postData);      
};

// 실행 작업 목록을 얻는다
export const GetTodos = async (user_id) => {
  let url = `${routerServer}/db/get_todos/${user_id}`;;
  //console.log(url);
  return await fetchDataFromDB(url);
};

// 실행 작업을 삭제한다
export const DeleteTodo = async (todo_id) => {
  let url = `${routerServer}/db/delete_todo/${todo_id}`;;
  //console.log(url);
  return await fetchDataFromDB(url);
};

// 앱 프로모션 초대코드를 적용시킨다 
export const SetPromotionCode = async (invite_code, user_id) => {
  const postData = {
    invite_code : invite_code,
    user_id : user_id,
  };
  const url = `${routerServer}/db/set_promotion_code`;
  //console.log(url);
  return await saveDataToDB(url, postData);      
};

// 앱 상태를 저장한다
export const SetAppStatus = async (is_active, user_id) => {
  const postData = {
    is_active : is_active,
    user_id : user_id,
  };
  const url = `${routerServer}/db/set_app_status`;
  //console.log(url);
  return await saveDataToDB(url, postData);      
};

// 마케터 초대코드를 적용시킨다 
export const SetMktCode = async (invite_code, user_id) => {
  const postData = {
    invite_code : invite_code,
    user_id : user_id,
  };
  const url = `${routerServer}/db/set_mkt_code`;
  //console.log(url);
  return await saveDataToDB(url, postData);      
};

// 마케터 초대코드를 적용시킨다 
export const SetMktCode0 = async (invite_code) => {
  const postData = {
    invite_code : invite_code,
  };
  const url = `${routerServer}/db/set_mkt_code_cnt`;
  //console.log(url);
  return await saveDataToDB(url, postData);      
};
