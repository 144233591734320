import React, {useState, useEffect} from 'react';
import { View, Text, Image, StyleSheet, TouchableOpacity, Dimensions, Linking, ActivityIndicator} from 'react-native';
import GSS from '../GlobalStyleSheet2';
import { getOperatingSystem, setTargetAfterLogin } from '../Global';
import { useFocusEffect, CommonActions } from '@react-navigation/native';

const GoProc = ({navigation, route}) => {
    const [ OS, setOS] = useState();

    useFocusEffect (
        React.useCallback(() => {
            (async () => {
                const operatingSystem = getOperatingSystem();
                
                if( operatingSystem === 'Android') {
                    const url = `https://myshott.page.link/promo?invite_code=0`;
                    const canOpen = await Linking.canOpenURL(url);      
                    if (canOpen) {
                        await Linking.openURL(url);
                    } else {
                        const appStoreUrl = "https://play.google.com/store/apps/details?id=com.myshott.eva";
                        Linking.openURL(appStoreUrl).catch(err => {
                            //console.error('Failed to open URL:', err);
                            navigation.dispatch(
                                CommonActions.reset({
                                    index: 0,
                                    routes: [{ 
                                        name: 'Home',
                                    }],
                                })
                            );                               
                        });
                    }
                } else {
                    navigation.dispatch(
                        CommonActions.reset({
                            index: 0,
                            routes: [{ 
                                name: 'Home',
                            }],
                        })
                    );   
                }               
          })();          
        }, [])
    );       
  
    return (
    <View style={GSS.mainContainer}>
        <View style={{flexDirection: 'row', alignItems:'center', marginTop:25, marginBottom:25}}>
            <View style={{flex:1}}>
                <Text style={SS.logoTitleText}>마이샷</Text>
            </View>
        </View>
        <View style={GSS.mainViewBox}>
            <View style={GSS.activityIndicatorContainer}>
                <ActivityIndicator size='large' color="#0000ff" />
            </View>    
        </View>       
    </View>
    );
};


const SS = StyleSheet.create({
    logoTitleText: {
    textAlign: 'center',
    fontSize: 40,
    color: '#00e5e0',
    fontFamily: 'Gaegu'
    },
    loginImage: {
        width: 30, // 이미지 너비
        height: 30, // 이미지 높이
        borderRadius: 15,
        backgroundColor: '#291B44',
        padding: 5,
        marginRight: 5,
    },
    confirmView: {
        //flex: 
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems : 'center',
    },     
});

export default GoProc;