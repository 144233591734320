import React, { useEffect } from 'react';
import { SafeAreaView, View, Text, Platform, ActivityIndicator} from "react-native";
import { WebView } from 'react-native-webview';
import { useEnv } from './AppContext';
import { routerServer } from './Global';
import GSS from './GlobalStyleSheet2.js'
import { useLoginProc } from './LoginProc2';
import { getTargetAfterLogin } from './Global';
import { useFocusEffect } from '@react-navigation/native';
import * as Linking from "expo-linking"

// const runFirst = `window.ReactNativeWebView.postMessage("this is message from web");`;

export default function LoginApple({navigation, route}) {
    const {ENV} = useEnv();
    const { loginProc } = useLoginProc();
    let user_id = route.params?.user_id;
    const target_after_login = route.params?.target_after_login;

    const newENV = {
        user_id : null,
        last_login_type : null
    }

    const navigateAfterLogin = async () => {
        //console.log( newENV.user_id);
        if( newENV.user_id) {
            await loginProc(newENV);
        } 
        navigation.navigate(getTargetAfterLogin(), {login_before : true});   
    };        

    if(Platform.OS !== 'web') {
        onMessage = (event) => {
            const uri = event.nativeEvent.url;
            //console.log(uri);
            if(uri.startsWith(routerServer))
            {
                // 서버로부터의 응답 데이터 처리
                const ret = JSON.parse(event.nativeEvent.data);
                newENV.user_id = ret.user_id;
                newENV.last_login_type = ret.last_login_type;
                navigateAfterLogin();
            }        
        }
    
        return (
            <SafeAreaView style={GSS.droidSafeArea}>
                <WebView 
                    originWhitelist={['*']}
                    scalesPageToFit={false}
                    source={{ uri: `${routerServer}/apple/call_apple`, webSecurityEnabled: false }}
                    javaScriptEnabled={true}
                    onMessage={onMessage}
                    //source={{ html: htmlContent }}
                />
            </SafeAreaView>
        );   
    } else {
        useFocusEffect (
            React.useCallback(() => {
              // 포커스될 때 실행할 코드
              // console.log('스크린이 포커싱되었습니다.');
              if(user_id) {
                //console.log(user_id);
                (async () => {
                    //   try {
                    //       const newENV = {};
                    //       newENV.user_id = user_id;
                    //       newENV.last_login_type = last_login_type;
                    //       //console.log(newENV);
                    //       await loginProc(newENV);
                    //       navigation.navigate(getTargetAfterLogin(), {login_before : true});
                    //   } catch (e) {
                    //       console.error(e);
                    //   }
                  })();          
              } else {
                const prefix = Linking.createURL("/");
                const url = `${routerServer}/apple/call_apple_web/${encodeURIComponent(prefix)}`;
                window.location.href = url;               
              }        
              return () => {
                // 스크린이 포커스를 잃을 때 실행할 정리(clean-up) 코드
                // console.log('스크린이 포커스를 잃었습니다.');
                if(user_id) {
                  user_id = null;
                  last_login_type = null;
                }
              };
            }, [])
        );

        if(user_id ) {
            return (
                <View style={GSS.mainContainer}>
                    <View style={{alignItems: 'center', justifyContent:'center'}}>
                        <Text style={GSS.mainDefaultText}>로그인되었습니다</Text>
                        <View style={GSS.activityIndicatorContainer}>
                            <ActivityIndicator size='large' color="#0000ff" />
                        </View>                    
                    </View>      
                </View>
            );
        } else {
            return (
                <View style={GSS.mainContainer}>
                    <View style={{alignItems: 'center', justifyContent:'center'}}>
                        <Text style={GSS.mainDefaultText}>로그인...</Text>
                        <View style={GSS.activityIndicatorContainer}>
                            <ActivityIndicator size='large' color="#0000ff" />
                        </View>                    
                    </View>      
                </View>
            );
        }
    }
}