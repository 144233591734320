import React, { useState, useEffect } from 'react';
import { Alert } from 'react-native';
import { ReadUserItemFromDB } from './api.js'

const UserItemContext = React.createContext();

export const UserItemContextProvider = ({ children }) => {
    const [UserItem, setUserItem] = useState({
        t_user_item : [],
        t_used_item : [],
        t_waiting_list : [],
        t_user_message : [],
        t_sys_message : []
    });

    const ReadUserItem = async (user_id) => {
        try {
          const result = await ReadUserItemFromDB(user_id);
          if (result.success) {
            setUserItem(result.data); // 데이터를 직접 설정
            return result; // 새로운 데이터를 반환
          } else {
            Alert.alert("Error", `Error reading user item: ${result.error}`);
            throw new Error(`Error reading user item: ${result.error}`);
          }
        } catch (error) {
          console.log('사용자 아이템 정보 읽기 오류 : ', error);
          throw error; // 에러를 다시 던져서 호출하는 쪽에서 처리할 수 있도록 함
        }
    };

    return (
        <UserItemContext.Provider value={{ UserItem, setUserItem, ReadUserItem }}>
         {children}
        </UserItemContext.Provider>
    );
};

export function useUserItem() {
    return React.useContext(UserItemContext);
}
