import React, { useEffect, useState} from 'react';
import { View, ImageBackground, StyleSheet, Alert, ActivityIndicator } from 'react-native';
import myshottLogoImage from '../assets/logo-back.png';
import { useNavigation } from '@react-navigation/native';
import { useLoginProc } from './LoginProc2';
import { useInitProc } from './InitProc';

function SplashScreen() {
    //const { isLoggedIn, login, logout } = useAuth();
    const navigation = useNavigation();
    const [isLoading, setIsLoading] = useState(true); // 초기 상태에서는 로딩 중으로 설정
    const { loginProc } = useLoginProc();
    const { initProc} = useInitProc();

    useEffect(() => {
        let isMounted = true;
    
        const initialize = async () => {
            try {
                if (isMounted) {
                    setIsLoading(true);
                    const newENV = await initProc();
                    //console.log(newENV);
                    setIsLoading(false);
                    goNavi(newENV);
                }
           } catch (e) {
                console.error(e);
                Alert.alert("Error", e.toString());
                if (isMounted) {
                    setIsLoading(false);
                    navigation.reset({
                        index: 0,
                        routes: [{ name: 'home' }],
                      });  
                }
            }
        };
    
        initialize();
    
        return () => {
            isMounted = false;
        };
    }, []);

    const goNavi = async (newEnv) => {
        // console.log('SplashScreen goNavi() entered');
        await loginProc(newEnv);
        //console.log('SplashScreen goNavi() go home');
        navigation.reset({
            index: 0,
            routes: [{ name: 'home' }],
          });  
    };

    return (
        <View style={styles.container}>
            <ImageBackground source={myshottLogoImage} 
                style={styles.image}
                resizeMode='cover'
            >
            {isLoading && (
                <View style={styles.activityIndicatorContainer}>
                <ActivityIndicator size="large" color="#0000ff" />
                </View>
            )}
            </ImageBackground>
        </View>        
    );
}
    
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    logo: {
        fontSize: 24,
        fontWeight: 'bold',
    },
    image: {
        width: 500, // 원하는 너비
        height: 500, // 원하는 높이
    },   
    activityIndicatorContainer: {
        flex: 1,
        justifyContent: 'center', // 세로 축에서 중앙에 위치
        alignItems: 'center', // 가로 축에서 중앙에 위치
    },    
});

export default SplashScreen;