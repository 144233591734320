import React, {useState, useEffect } from 'react';
import { StyleSheet, Text, View, ScrollView , TouchableOpacity, Image, Animated, Platform } from 'react-native';
import { useAuth, useEnv, OTTs, useSystemInfo} from '../AppContext';
import { useItemContext } from '../ItemContext';
import ConfirmImage from '../ConfirmImage';
import { useModal } from '../ModalContext';
import bellImage from '../../assets/bell.png';
import BlinkingText from '../BlinkingText';
import GSS from '../GlobalStyleSheet2';
import TopHeader from '../TopHeader';
import ProgressButton, { ProgressNext } from '../ProgressButton';
import { CheckCertiIDOfDB } from '../api';
import { useFocusEffect } from '@react-navigation/native';
import { setTargetAfterLogin, targetOS } from '../Global';

function NetShare1 ({navigation, route}) {
    const { isLoggedIn  } = useAuth();
    const { ENV } = useEnv();
    const {Item, setItem} = useItemContext();
    const [confirmImageActive, setConfirmImageActive] = useState(false);
    const { openModal, closeModal } = useModal();
    const [selectedNumberValue2, setSelectedNumberValue2] = useState(2);
    let modalID;
    const [maxNum2, setMaxNum2] = useState(2);
    const [minNum2, setMinNum2] = useState(1);
    const {systemInfo} = useSystemInfo();
    const [isCheckingCertiID, setIsCheckingCertiID] = useState(false);

    function NextStep()
    {
      //console.log(selectedNumberValue);
      const newData = { ...Item };
      newData.maximum_usage2 = selectedNumberValue2;
      newData.remaining_usage2 = selectedNumberValue2;
      setItem(newData); 
      let nextTarget;
      if(Item.free_charge === true) {
        nextTarget = 'ManagerShare3';
      } else {
        nextTarget = 'ManagerShare12';
      }
      if( isCheckingCertiID ) {
        navigation.navigate(nextTarget);
      } else {
        const targetAfterRegister = nextTarget;
        navigation.navigate('CertifyID', {targetAfterRegister});
      }
    }

    useFocusEffect (
      React.useCallback(() => {
          (async () => {
              try {
                if(isLoggedIn) {
                  const ret = await CheckCertiIDOfDB(ENV.user_id);
                  //console.log( 'ret : ', ret);
                  if( ret.success) {
                      //console.log(ret.data);
                      if( ret.data.checked_certification === 1 ) {
                          setIsCheckingCertiID(true);
                      }
                  }                   
                }
              } catch (e) {
                  console.error(e);
              }
        })();          
      }, [Item.user_id])
    ); 

    useFocusEffect (
      React.useCallback(() => {
        //console.log(Item);
        if( Item.maximum_usage2 ) {
          setSelectedNumberValue2(Item.maximum_usage2);
          handlePress2(Item.maximum_usage2);
        } else {
          setSelectedNumberValue2(2);
          handlePress2(2);
        }
      }, [])
    );              

    useEffect(() => {
      // async 함수를 정의하고 즉시 호출합니다.
      (async () => {
        try {
          if(isLoggedIn) {
                const newData = { ...Item };
                newData.user_id = ENV.user_id;
                setItem(newData);
          } else {
              setTargetAfterLogin('NetShare1');
              if(Platform.OS == 'web')
              {
                navigation.navigate('LoginWeb');
              } else {
                navigation.navigate('Login');
              }                   
          }
        } catch (e) {
          // 오류를 적절하게 핸들링합니다. (예: 로깅, 사용자에게 메시지 표시 등)
          console.error(e);
        }

        // // 텝 화면이 진입하면 바로 모달창을 띄운다
        // const unsubscribe = navigation.addListener('focus', () => {
        //   openModal( ModalScreen, 0.35 );
        // });
        // // 컴포넌트가 unmount될 때 이벤트 리스너 제거
        // return unsubscribe;
      })();
    }, [isLoggedIn, navigation]); 

    // useEffect(() => {
    //   console.log(selectedNumberValue2);
    // }, [selectedNumberValue2]); 

    const [selectedNumber2, setSelectedNumber2] = useState(null);
    const [fadeAnim] = useState(new Animated.Value(0));
  
    const DetailedInfoView = ({ S2 }) => {
      const netfixPrice = systemInfo.netflix_price + S2 * 5000;
      const receiverPrice = S2 * Item.monthly_price2;
      const managerPrice = netfixPrice - receiverPrice;
      return (
        <View style={[GSS.mainViewBox, { padding:15}]}>
          <Text style={GSS.mainInfoText}>
            {Item.display_name} {netfixPrice.toLocaleString()}원/월
          </Text>            
          <Text style={GSS.mainInfoText}>
            - 내 부담금 : <Text style={GSS.emphasisText}>{managerPrice.toLocaleString()}</Text>원/월
          </Text>
          <Text style={GSS.mainInfoText}>
            - 파티원 {S2}명의 지급금 : <Text style={GSS.emphasisText}>{receiverPrice.toLocaleString()}</Text>원/월
          </Text>        
        </View>
      );
    };   
    
  const handlePress2 = (number) => {
    setCount2(number);
    setSelectedNumberValue2(number);
    setSelectedNumber2(number);

    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 1000,
      useNativeDriver: true,
    }).start();
  };    
   
  const [count2, setCount2] = useState(2);
  const handleMinusButtonPress2 = () => {
    setCount2(prev => {
      const newCount = Math.max(prev - 1, minNum2);
      handlePress2(newCount);
      return newCount;
    });
  };
  const handPlushButtonPress2 = () => {
    setCount2(prev => {
      const newCount = Math.min(prev + 1, maxNum2);
      handlePress2(newCount);
      return newCount;
    });      
  }
  const renderNumberButtons2 = () => {
    return(
      <View style={{flexDirection: 'row', alignItems:'center', justifyContent: 'space-between'}}>
        {/* "-" 버튼 */}
        <TouchableOpacity 
          style={[SS.plusminusButton, count2 <= minNum2 ? SS.plusminusButtonDisabled : null]} 
          onPress={handleMinusButtonPress2}
          disabled={count2 <= minNum2}>
          <Text style={[SS.plusminusText, count2<=minNum2 ? SS.plusminusTextDisabled : null]}>-</Text>
        </TouchableOpacity>

        {/* 중앙의 숫자 표시 */}
        <Text style={[SS.countNumber]}>{count2}</Text>

        {/* "+" 버튼 */}
        <TouchableOpacity 
          style={[SS.plusminusButton, count2 >= maxNum2 ? SS.plusminusButtonDisabled : null]} 
          onPress={handPlushButtonPress2}     
          disabled={count2 >= maxNum2}>
          <Text style={[SS.plusminusText, count2 >= maxNum2 ? SS.plusminusTextDisabled : null]}>+</Text>
        </TouchableOpacity>
      </View>
    );
  }; 

  const confirmPress = () => {
    if (!confirmImageActive) {
      setConfirmImageActive(true);
      handleOpenModal();
    } else {
      setConfirmImageActive(false);
    }
  };

  const ModalScreen = () => {
    const _item = OTTs[Item.ott_id];
    return (
      <View style={{ 
        flex: 1, 
        //justifyContent: 'space-between', // 컨텐츠 사이에 공간을 만듭니다.
        padding: 3, // 내부 여백을 추가합니다.
        //borderWidth: 1, 
        backgroundColor: '#FFFFFF',
      }}> 
        <View style={{
          //borderWidth: 1,
          // flex:0.4,
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
          <Image
            source={bellImage}
            style={{ width: 25, height: 25 }}
          />          

        </View>      
        <View style={{
          flex : 1,
          backgroundColor: '#F1F1F1',
          //alignItems : 'center',
          justifyContent: 'center',
          padding: 5,
          //borderWidth: 2, 
          //borderColor: '#FF00FF'
        }}>
          <Text style={[GSS.mainInfoText, {marginBottom : 5}]}>• {Item.display_name}을 앱(APP)에서 결제할 경우 인앱 결제 수수료가 추가 되어 <Text style={GSS.emphasisText}>정가보다 비쌉니다</Text></Text>
          <Text style={[GSS.mainInfoText, {marginBottom : 5}]}>• {Item.display_name} <Text style={GSS.emphasisText}>웹사이트</Text>에서 결제를 하면 <Text style={GSS.textEmphasis}>{_item.site_price.toLocaleString()}원에 결제</Text>가 가능합니다</Text>
          <Text style={[GSS.mainInfoText, {marginBottom : 5}]}>• 넷플릭스에서 최대 2명의 <Text style={GSS.emphasisText}>추가회원</Text>을 만들 수 있습니다. <Text style={GSS.emphasisText}>추가회원</Text>당 <Text style={GSS.textEmphasis}>5,000원</Text>의 금액이 소요됩니다.</Text>
          <Text style={[GSS.mainInfoText, {marginBottom : 5}]}>• <Text style={GSS.emphasisText}>추가회원</Text>은 TV를 포함한 모든 기기에서 이용 가능 하고 별도의 계정을 관리하게 되어 파티장,파티원 모두 편리합니다</Text>
               <Text style={[GSS.mainInfoText, {marginBottom : 5}]}>• <Text style={GSS.emphasisText}>추가회원</Text> 당 <Text style={GSS.textEmphasis}>{Item.monthly_price2.toLocaleString()}원</Text>을 지급받습니다.</Text>
          { systemInfo.trade_manager_charge < 490 ? (
            <View>
              <Text style={[GSS.mainInfoText]}>• 현재 마이샷 수수수료 이벤트로 인해 490원이 아닌 <Text style={GSS.textEmphasis}>{systemInfo.trade_manager_charge?.toLocaleString()}원</Text>으로 할인 받습니다.</Text>
            </View>
          ) : (
            <View>
              <Text style={GSS.mainInfoText}>• 정산시 정산금액에서 수수료 {systemInfo.trade_manager_charge.toLocaleString()}원이 부과됩니다.</Text>
            </View>
          )}
        </View>
        <View style={{ alignItems: 'center', justifyContent: 'center'}}>
          <ProgressButton
            title = '확인'
            onPress={() => closeModal(modalID)} 
            style={{alignItems:'center'}}
          />
        </View>
      </View>       
    );
  }

  const handleOpenModal = () => {
    modalID = openModal(<ModalScreen />, 0.7);
  };  

  const NextButton = () => {
    return (
      <ProgressNext
        title={'다음'}
        onPress={NextStep}
        disabled={(confirmImageActive &&  selectedNumber2>0) ? false : true}
      />
    );
  }

  return (
    <View style={GSS.mainContainer}>
      <TopHeader 
        titleImage={Platform.OS !== targetOS && OTTs[Item.ott_id].iconImage}
        navigation={navigation}
      >
        {Item.display_name} 파티 생성
      </TopHeader>
      <View style={[GSS.mainViewBox, SS.confirmView]}>
          <BlinkingText 
            shouldBlink={!confirmImageActive}
            textStyle={GSS.mainInfoTextB}
          >'<Text style={{color:'#fb3c67'}}>{Item.display_name}</Text>' 프리미엄 계정이 있으신가요?</BlinkingText>
          <ConfirmImage
            isActive={confirmImageActive}
            onPress={confirmPress}
            noDisabled={false}
          />
      </View>
      <View style={[GSS.mainViewBox, { flex: 8, alignItems : 'center' }]}>
        <View style={{marginBottom:5, marginTop:15}}>
          <Text style={GSS.mainInfoTextB}>모집할 추가회원 수를 선택</Text>
        </View>
        <View style={SS.buttonsContainer}>{renderNumberButtons2()}</View>
          <Animated.View
            style={[
              SS.fadingContainer,
              {
                opacity: fadeAnim,
              },
          ]}>
          {/* 선택된 라벨을 직접 표시합니다. */}
          {selectedNumber2 >= 0 && (
              <Text style={SS.numberDisplay}>
                추가회원 <Text style={GSS.emphasisText}>{selectedNumber2}명</Text>을(를) 모집합니다
              </Text>
          )}
        </Animated.View>       
        {/* 선택된 항목에 대한 상세 정보를 렌더링합니다. */}
        {selectedNumber2>0 && 
          <DetailedInfoView 
            S2={selectedNumber2} />}            
      </View>  
      <View style={[GSS.mainViewBox, { flex: 1.5, alignItems:'center' }]}>
          <NextButton/>
      </View>
    </View>
  );
};

const SS = StyleSheet.create({
  plusminusButton: {
    height: 30,
    width: 30,
    borderRadius: 15,
    borderColor: '#00e5e0',
    borderWidth: 2,
    alignItems: 'center',
    margin: 10
  },
  plusminusButtonDisabled: {
    borderColor: '#f1f3f6'
  },
  plusminusText: {
    fontSize: 20,
    color: '#291B44',
    fontFamily: 'Audiowide',   
  },
  plusminusTextDisabled: {
    color: '#e2e0e5'
  },
  countNumber: {
    fontSize: 30,
    fontFamily: 'Audiowide',
    color: '#291B44',
    marginHorizontal: 35,
  },
  numberDisplay: {
    fontSize: 18, 
    fontWeight: 'bold',
    color: '#01579b',
  },  
  confirmView: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems : 'center'
  }, 
  buttonsContainer: {
    flexDirection: 'row',
  },
  fadingContainer: {
    // paddingVertical: 2,
    // paddingHorizontal: 8,
    backgroundColor: "rgba(255,255,255,0.6)",
    //borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
  }, 
});

export default NetShare1;