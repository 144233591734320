import React, {useState, useEffect, useRef} from 'react';
import { Platform, } from 'react-native';
import { LoadFromSystem } from './Global';
import { useLoginProc } from './LoginProc2';
import { useAuth, useEnv } from './AppContext';
import { useUserItem } from './UserItemContext';
import * as Notifications from 'expo-notifications';
import { useModal } from './ModalContext';
import NetInfo from '@react-native-community/netinfo';
import * as Linking from 'expo-linking';

const GlobalAppStatus = () => {
  const { loginProc } = useLoginProc();
  const { ENV, setIsNetworkConnected } = useEnv();
  const { ReadUserItem } = useUserItem();
  const { showModalMessage, showMessage } = useModal();

  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener(state => {
      setIsNetworkConnected(state.isConnected);
      // console.log("Connection type", state.type);
      // console.log("Is connected?", state.isConnected);
    });
    return () => {
      if (unsubscribe.remove) {
        unsubscribe.remove();
      } else if (typeof unsubscribe === 'function') {
        unsubscribe(); // 최신 React Native 버전에서는 이 방식을 사용
      }
    };
  }, []);  

  // useEffect(() => {
  //   console.log('GlobalAppStatus called.');
  // }, []);  

  if( Platform.OS === 'web') {
    //console.log( LoadFromSystem() );
    useEffect(() => {
      // async 함수를 정의하고 즉시 호출합니다.
      (async () => {
        try {
          //const sysData = await LoadFromSystem();
          // 컴포넌트가 여전히 마운트 상태인 경우에만 상태를 설정하거나 로깅을 수행합니다.
          //console.log(ENV);
          if(!ENV || ENV.user_id===null) {
            const sysData = await LoadFromSystem();
            const newENV = {};
            if(sysData && sysData.user_id ) {
                newENV.user_id = sysData.user_id;
                newENV.last_login_type =sysData.last_login_type;
                // newENV.kakao_id = sysData.kakao_id;
                newENV.message_ymd = sysData.message_ymd;
                newENV.t_item = sysData.t_item;
                newENV.item_check_time = sysData.item_check_time;
                await loginProc(newENV);
            }            
          }
        } catch (e) {
          // 오류를 적절하게 핸들링합니다. (예: 로깅, 사용자에게 메시지 표시 등)
          console.error(e);
        }
      })();
    }, [ENV.user_id]);  
  } else {
    if(Platform.OS !== 'web') {
      const [notification, setNotification] = useState(null);
      // const [error, setError] = useState(null);
      useEffect(() => {
        const subscription = Notifications.addNotificationResponseReceivedListener(response => {
          try {
            // 알림 데이터 설정
            setNotification(response.notification);
          } catch (err) {
            // 에러 핸들링
            //setError(err);
            console.error("알림 처리 중 에러 발생:", err);
          }
        });
      
        return () => {
          if (subscription.remove) {
            subscription.remove();
          } else if (typeof subscription === 'function') {
            subscription(); // 최신 React Native 버전에서는 이 방식을 사용
          }
        };
      }, []);
    
      useEffect(() => {
        // 포그라운드에서 알림을 받을 때 호출될 리스너
        const subscription = Notifications.addNotificationReceivedListener(notification => {
          //console.log("포그라운드 알림 수신:", notification);
          // 여기서 사용자 정의 UI를 표시하거나 다른 처리를 할 수 있습니다.
          setNotification(notification);
        });
    
        return () => {
          if (subscription.remove) {
            subscription.remove();
          } else if (typeof subscription === 'function') {
            subscription(); // 최신 React Native 버전에서는 이 방식을 사용
          }
        };
      }, []);  
    
      useEffect(() => {
        if (notification) {
          try {
            const title = notification.request.content.title;
            const body = notification.request.content.body;
            //const extraData = response.notification.request.content.data.extraData;
            //console.log(title, body);      
    
            const _message = {
              title : title,
              body : body,
              afterOK : async () => { 
                await ReadUserItem(ENV.user_id);
                //navigation2.navigate('home');
              }
            };
            showModalMessage(_message);
    
            //Alert.alert(title, body);
      
            // // 상태 초기화
            // setNotification(null);
          } catch (err) {
            //setError(err);
            console.error("Alert 표시 중 에러 발생:", err);
          }
        }
      }, [notification]);
  
      // // 에러 상태에 대한 처리 (옵션)
      // useEffect(() => {
      //   if (error) {
      //     // 에러 처리 로직 (예: 사용자에게 에러 메시지 표시)
      //     //Alert.alert('에러 발생', error.message);
      //     console.log('에러 발생', error.message);
      //     setError(null);
      //   }
      // }, [error]);

      // 딥 링크 처리 함수
      const handleDeepLink = React.useCallback((event) => {
        // 여기서는 이벤트 객체 또는 URL 문자열을 처리할 수 있도록 유연성을 제공합니다.
        const url = typeof event === 'string' ? event : event.url;
        const { path, queryParams } = Linking.parse(url);

        if (path === 'promo') {
          const paramValue = queryParams?.invite_code; // 변경된 부분
          if (paramValue) {
            //showMessage(`Invite code: ${paramValue}`);
          }
        } else if(path === 'mkt' ) {

        }
      }, []);

      useEffect(() => {
        // 초기 딥 링크 처리
        Linking.getInitialURL().then((url) => {
          if (url) handleDeepLink(url);
        });

        // 앱 실행 중 딥 링크 이벤트 리스닝
        const unsubscribe = Linking.addEventListener('url', handleDeepLink);

        return () => {
          if (unsubscribe.remove) {
            unsubscribe.remove();
          } else if (typeof unsubscribe === 'function') {
            unsubscribe(); // 최신 React Native 버전에서는 이 방식을 사용
          }
        };
      }, [handleDeepLink]);   
    }
  }

   return null;
}

export default GlobalAppStatus;