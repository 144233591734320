import React from 'react';
import { View, Linking, Pressable, Image, Text } from 'react-native';
import GSS from '../GlobalStyleSheet2';
import KaTalkImage from '../../assets/images/kakaotalk.png'

function ChatHome ({navigation, route}) {

    const goChat = () => {
        window.location.href = "http://pf.kakao.com/_PxecjG/chat";
    }
    
    return (
        <View style={[GSS.mainContainer, {alignItems:'center'}]}>
            <View style={{width:'60%'}}>
                <Text style={GSS.mainInfoText}>마이샷 카카오톡 채널을 통해 관리자와 1:1 상담톡을 진행합니다</Text>
                <Text style={GSS.mainInfoText}>관리자의 상담톡 진행 중인 경우 상담을 위한 대기시간이 있을 수 있음을 양해 해 주세요</Text>
            </View>
            <View style={{ alignItems:'center', backgroundColor:'#fee500', borderRadius:5, width:'60%', marginTop:20}}>
                <Pressable activeOpacity={0.5} onPress={goChat}>
                <View style={{flexDirection:'row', padding: 10 }}>
                    <Image source={KaTalkImage} style={{height:25, width:25, marginRight:10}}></Image>
                    <Text style={GSS.mainTitleText}>카카오톡 1:1 상담</Text>
                </View>
                </Pressable>
            </View>           
        </View>
    );
};

export default ChatHome;