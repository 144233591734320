import React, {useState, useEffect} from 'react';
import { Text, View, TouchableOpacity , Image, StyleSheet, ActivityIndicator, Platform, Dimensions, Linking } from 'react-native';
import GSS from '../GlobalStyleSheet2';
import { GetOTTByName } from '../AppContext';
import PrevImage from '../../assets/prev2.png';
import SettingImage from '../../assets/settings.png'
import UserImage from '../../assets/user.png';
import CopyImage from '../../assets/copy.png';
import { GetIdPwdFromDB, GetAccountEmailofDB, DeleteAccountEmail } from '../api';
import { showToast, SplitNickName, targetOS } from '../Global';
import * as Clipboard from 'expo-clipboard';
import TopHeader from '../TopHeader';
import { ScrollView } from 'react-native-gesture-handler';
import * as Animatable from 'react-native-animatable';
import nextImage from '../../assets/next.png';
import toEditor from '../../assets/comment.png';
import { useModal } from '../ModalContext';
import { useUserItem } from '../UserItemContext';
import { useFocusEffect } from '@react-navigation/native';
import LinkImage from '../../assets/share.png';

function DisplayUserItem ({navigation, route}) {
    const [user_item, setUserItem] = useState(route.params.user_item);
    const [item, setItem] = useState(route.params.item);
    const [userAccounts, setUserAccounts] = useState(route.params.userAccounts);
    const OTT = GetOTTByName(item.item_name);
    const [profiles, setProfiles] = useState([]);
    const [ account_id, setAccountID] = useState('');
    const [ account_pwd, setAccountPWD] = useState('');
    const [ adult_pwd, setAdultPWD ] = useState('');
    const [ checkDisplayID, setCheckDisplayID] = useState(false);
    const [ checkDisplayPWD, setCheckDisplayPWD] = useState(false);
    const [checkDisplayEmail, setCheckDisplayEmail] = useState(false);
    const { showMessage, confirmModal, closeModal} = useModal();
    const { ReadUserItem } = useUserItem();
    const [isLoading, setIsLoading] = useState(false);

    // useEffect(() => {
    //     console.log(userAccounts);
    // }, []);        

    const prevAction = () => {
        navigation.goBack();
    }

    const createProfileArray = () => {
        if( user_item.maximum_usage === 0 ) return [];
        let myNickNames = user_item.nick_name.split('|||');
        if( user_item.maximum_usage === 4 ) myNickNames = [];
        let newProfiles = [];
        for (let i = 0; i < myNickNames.length; i++) {
          newProfiles.push({ type: '내프로필', title: '내 프로필', nick_name: myNickNames[i] });
        }

        if( user_item.userNickNames )
        {
            const userNickNames = user_item.userNickNames.split('|||');
            for (let i = 0; i < userNickNames.length; i++) {
                const nick = SplitNickName(userNickNames[i]).nick_name;
                newProfiles.push({ type: '파티원', title: '파티원', nick_name: nick });
            }
        }

        while (newProfiles.length < 4) {
            newProfiles.push({ type: '파티원', title:'파티원', nick_name: '자동매칭' });
        }

        return newProfiles;
    };

    useFocusEffect (
        React.useCallback(() => {
            (async () => {
                try {
                    const { user_item } = route.params;
                    if(user_item?.item_id !== 1 ) {
                        const initialProfiles = createProfileArray(); 
                        setProfiles(initialProfiles);
                    }                   
                    setUserItem(user_item); // 상태 업데이트
                    setAccountID(user_item.account_userid);
                    setAccountPWD(user_item.account_pwd);
                    setAdultPWD(user_item.adult_pwd);
                } catch (e) {
                    console.error(e);
                }
          })();          
        }, [route.params.user_item])
    ); 

    const updateProfile = () => {
        navigation.navigate('UpdateProfileOfHost', { user_item, item });
    }

    const sendMessageToUser = (nick_name, new_netflix = false) => {
        //console.log(nick_name);
        navigation.navigate('SendHostMessage', { user_item, nick_name, new_netflix });
    }    

    const ActionView = ({type, index, nick_name}) => {
        //console.log(type, index, nick_name);
        if(type === '내프로필') {
            if(index > 0)  {
                return (
                    <View style={SS.emptyContainer}>
                        <Text></Text>
                    </View>
                );
            } else {
                return (
                    <TouchableOpacity  onPress={updateProfile}>
                        <View style={SS.actionContainer}>
                            <Text style={{color:'#42494f'}}>수정</Text>
                        </View>
                    </TouchableOpacity>
                );            
            }
        } else if (type ==='파티원' ) {
            if( nick_name === '자동매칭') {
                return (
                    <View style={SS.emptyContainer}>
                        <Text></Text>
                    </View>
                );
            } else {
                return (
                    <TouchableOpacity  onPress={() => sendMessageToUser(nick_name)}>
                        <View style={SS.actionContainer}>
                          <Text style={{color:'#42494f'}}>쪽지</Text>
                        </View>
                    </TouchableOpacity>
                );
            }
        } else {
            return null;ß
        }
    }    

    let keyIndex = 10;
    const ShowProfile = ({type}) => {
        //console.log(type);
        const ps = profiles.filter(obj => obj.type === type);
        if( ps.length === 0) return null;
        //console.log(ps);
        keyIndex += 10;
        if( ps.length === 1) {
            return(
                <View>
                    <View style={SS.titleContainer}>
                        <Text style={{color:'#42494f'}}>{ps[0].title}</Text>
                    </View>
                    <View style={SS.iconWrapper}>
                        <Image source={ps[0].nick_name==='자동매칭' ? UserImage :  OTT.profileImage} style={SS.icon} />
                        <Text style={GSS.mainDefaultTextB}>{ps[0].nick_name}</Text>
                        <ActionView
                            type = {type}
                            index = {0}
                            nick_name = {ps[0].nick_name}
                        />                         
                    </View> 
                </View>                
            );
        } else {
            return (
                <React.Fragment>
                    <View>
                        <View style={SS.titleContainer}>
                            <Text style={{color:'#42494f'}}>{type}</Text>
                        </View>
                        <View style={{flexDirection:'row',justifyContent: 'space-between',position: 'relative', alignItems:'center'}}>
                            {ps.map((profile, index) => (
                                <React.Fragment key={index+keyIndex}>
                                    <View style={SS.iconWrapper}>
                                        <Image
                                            source={profile.nick_name === '자동매칭' ? UserImage : OTT.profileImage}
                                            style={SS.icon}
                                        />
                                        <Text style={GSS.mainDefaultTextB}>{profile.nick_name}</Text>
                                        <ActionView
                                             type = {type}
                                             index = {index}
                                             nick_name = {profile.nick_name}
                                        />                                         
                                    </View>
                                    {index !== ps.length - 1 && <View style={{ width: 25 }} />} 
                                </React.Fragment>
                            ))}                   
                        </View>
                    </View>
                </React.Fragment>
            );
        }
    }

    const DisplayParty = () => {
        return (
            <View style={SS.container}>
                <View style={SS.iconContainer}>
                    <ShowProfile
                        type={'내프로필'}
                    />
                    <ShowProfile
                        type={'파티원'}
                    />
                </View>
            </View>         
        );
    }

    function showDisplayID() {
        setCheckDisplayID(prevState => !prevState);
        setCheckDisplayPWD(false);
    }

    function showDisplayPWD() {
        setCheckDisplayPWD(prevState => !prevState);
        setCheckDisplayID(false);
    }   

    function showDisplayEmail() {
        setCheckDisplayEmail(prevState => !prevState);
    }     

    const copyToClipboard = async (text) => {
        await Clipboard.setStringAsync(text);
        if( Platform.OS === 'web') {
            showMessage(`[${text}]가 클립보드에 복사되었습니다`);
        } else {
            showToast('클립보드에 복사되었습니다.');
        }
    };  

    const SettingUsedItem = () => {
        navigation.navigate('SettingHostItem', { user_item, item, userAccounts });
    }    

    const changeIdPwd = () => {
        if(Platform.OS !== 'web') {
            if( user_item.item_id < 3 && user_item.using_myaccount !== 1) {
                navigation.navigate('ChangeOTTAccount', { user_item, item, userAccounts });
            } else {
                navigation.navigate('ChangeMyshottPassword', { user_item, item, OTT, userAccounts });
            }
        } else {
            if( user_item.item_id < 3 && user_item.using_myaccount !== 1) {
            } else {
                showMessage('안드로이드 또는 iOS 앱 프로그램에서 실행하시면 쉽고 편하게 비밀번호를 변경,관리합니다');
            }
            navigation.navigate('ChangeOTTAccount', { user_item, item, userAccounts });
        }       
    }

    let modalID = null;
    const GoNetflixProc = (checkAccounts) => {
        //console.log(checkAccounts);
        navigation.navigate('NetflixMatchedProc', { user_item, item, userAccounts: checkAccounts });

        // modalID = waitMessage('로딩 중.....');

        // setTimeout(() => {
        //     closeModal(modalID);

        //     showMessage('hi...........');
        //   }, 5000);
        
    }   

    const AddNetNetflix = async () => {
        // _userAccounts.push({
        //     account_id : user_item.account_email_id,
        //     account_status : user_item.first_status,
        //     account_email : user_item.first_email,
        //     used_item_id : user_item.first_used_item_id,
        //     message : UserItem.t_user_message.filter(obj => obj.is_host_message === 0
        //          && obj.user_item_id === user_item.user_item_id
        //          && obj.used_item_id === user_item.first_used_item_id )
        // });
        try {
            setIsLoading(true);
            const ret = await GetAccountEmailofDB(user_item.user_id, 1, user_item.user_item_id);
            setIsLoading(false);
            if( ret.success) {
                await ReadUserItem(user_item.user_id);
                const newAccounts = [...userAccounts];
                newAccounts.push({
                    account_id : ret.data[0].account_id,
                    account_status : ret.data[0].last_status,
                    account_email : ret.data[0].email,
                });
                setUserAccounts(newAccounts);
                const newUserItem  = {...user_item};
                newUserItem.maximum_usage2 += 1;
                //newUserItem.remaining_usage2 += 1;
                setUserItem(newUserItem);
                showMessage('추가회원이 추가되었습니다');
            } else {
                showMessage('추가회원을 추가 할 수 없습니다');
            }
        } catch (error) {
            console.log(error);
            showMessage('추가회원 추가 실폐(시스템오류)');
        }
    }

    const _DeleteNetflix = async (account, index) => {
        try {
            setIsLoading(true);
            const ret = await DeleteAccountEmail(user_item.item_id, user_item.user_item_id, account.account_id);
            setIsLoading(false);
            if( ret.success ) {
                const updatedAccounts = [...userAccounts];
                updatedAccounts[index] = {
                    ...updatedAccounts[index],
                    account_status: 0
                };           
                setUserAccounts(updatedAccounts);
            } else {
                
            }
        } catch (error) {
            console.log(error);
            showMessage('추가회원 공유 중지 실폐(시스템오류)');
        }
    }

    const DeleteNetflix = (account, index) => {
        const _confirm = {
            afterOK : () => _DeleteNetflix(account, index),
            isCode : true,
            body: () => (
                <View>
                    <Text style={GSS.mainInfoTextB}>넷플릭스 정책에 의해 추가회원 초대는 월 2회만 가능합니다.</Text>
                    <Text style={[GSS.emphasisText, {fontSize:16}]}>추가회원 공유 중지는 초대 1회 기회가 줄어 듭니다.</Text>
                    <Text style={GSS.mainInfoTextB}>추가회원 공유 중지를 진행 하시겠습니까?</Text>
                </View>
            )
        }
        confirmModal(_confirm);
    }

    //const windowWidth = Dimensions.get('window').width;
    const DisplayNetflix = () => {
        if(userAccounts.length === 0) return null;
        const checkAccounts = userAccounts.filter(obj => obj.account_status===0);
        return (
            <React.Fragment>
            <View style={{marginLeft:10}}>
                {userAccounts.map((account, index) => {
                    const isNew = account.account_status === 0 ? true : false;
                    let email = account.account_email;
                    return (
                        <React.Fragment key={`${account.account_id}-${index}`}>
                            <View style={{flexDirection:'row', marginTop:10}}>
                            {  isNew ? (
                                <Animatable.View key={`${account.account_id}-${index}`} animation="pulse" iterationCount="infinite" >
                                    <Animatable.View animation="customScale" iterationCount="infinite" direction="alternate">
                                        <View style={[SS.iconWrapper]}>
                                            <Image
                                                source={OTT.profileImage2}
                                                style={SS.icon}
                                            />
                                        </View>
                                        <Text style={[GSS.mainSmallText, { alignItems:'center'}]}>초대 필요</Text>
                                    </Animatable.View>
                                </Animatable.View>  
                            ) : (
                                <React.Fragment>
                                    <View key={`${account.account_id}-${index}`} style={[SS.iconWrapper, {position: 'relative'}]}>
                                        <Image
                                            source={OTT.profileImage}
                                            style={SS.icon}
                                        />
                                        { account.account_status > 1 && (
                                            <TouchableOpacity 
                                                onPress={() => sendMessageToUser(email, true)}
                                                style={{ position: 'absolute', top: -5, right: -5, height: 20, width: 20 }}
                                            >
                                                <Image
                                                    source={toEditor}
                                                    style={{ height: '100%', width: '100%' }}
                                                />
                                            </TouchableOpacity>
                                        )}
                                    </View>
                                </React.Fragment>
                            )}
                                <View style={[SS.textContainer, {flex:1, borderWidth:1, marginLeft:10, flexDirection:'column', alignItems:'flex-start'}]}>
                                    <Text style={{color:'#949494'}}>이메일 주소</Text>
                                    <View style={{ flex:1, width: Platform.OS === 'web' ? 800*0.7 : undefined , flexDirection:'row', justifyContent: 'space-between', }}>
                                        <View style={{ flex:1, flexDirection:'row', alignItems:'center'}}>
                                            <Text style={GSS.mainInfoText}>{checkDisplayEmail ? email : '●●●●●●●●'}</Text>
                                            <TouchableOpacity  onPress={() => copyToClipboard(email)}>
                                                <Image
                                                    source={CopyImage}
                                                    style={{width:20, height:20, marginLeft:5}}
                                                />
                                            </TouchableOpacity>
                                        </View>
                                        <TouchableOpacity onPress={showDisplayEmail}>
                                            <Text style={GSS.mainInfoText}>표시</Text>
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            </View>
                            { isNew === false && (
                                <TouchableOpacity
                                    style={{ flexDirection:'row', justifyContent:'space-between', alignItems:'center',}}
                                    onPress={() => {DeleteNetflix(account, index)}}
                                >
                                    <Text style={GSS.mainDefaultText}> 추가회원 공유 중지</Text>
                                    <Image
                                        source={nextImage}
                                        style={{width:30, height:30}}
                                    />
                                </TouchableOpacity>                                
                            )}
                        </React.Fragment>
                    );
                })}
            </View>
            { checkAccounts.length > 0 && (
                <TouchableOpacity
                    style={{ flexDirection:'row', justifyContent:'space-between', alignItems:'center',}}
                    onPress={() => {GoNetflixProc(checkAccounts)}}
                >
                    <Text style={GSS.mainInfoText}> 추가회원 초대하기</Text>
                    <Image
                        source={nextImage}
                        style={{width:40, height:40}}
                    />
                </TouchableOpacity>                                       
            )}
            </React.Fragment>
        );
    }

    const ChangeAccount = () => {
        if(Platform.OS === 'web') {
            showMessage('안드로이드 또는 iOS 앱 프로그램에서 실행해 주세요');
            return;
        }
        showMessage('준비중입니다...');
        if(user_item.item_id === 2)  { // 디지니
            //navigation.navigate('ChangeMyshottAccount', { user_item, item, OTT });
        }
    }

    const goOTTSite = async () => {
        try {
            if( Platform.OS === 'web') {
                //window.open('https://www.netflix.com/kr/', '_blank', 'width=1280,height=720');
                window.open(OTT.homeUrl, '_blank');
            } else {
                const supported = await Linking.canOpenURL(OTT.homeUrl);
                if (supported) {
                    // URL을 지원하고 기기의 기본 브라우저에서 열 수 있다면
                    await Linking.openURL(OTT.homeUrl);
                } else {
                    console.log(`Don't know how to open this URL: ${OTT.homeUrl}`);
                }
            }        
        } catch (error) {
            console.error('An error occurred', error);
        }
    }         

    return (
        <ScrollView>
            <View style={GSS.mainContainer}>
                <TopHeader 
                    titleImage={Platform.OS !== targetOS && OTT.logoImage}
                    navigation={navigation}
                >
                    {item.display_name} 파티 관리
                </TopHeader>  
                <View style={[GSS.mainViewBox]}>
                    <View style={{flexDirection:'row', justifyContent: 'space-between', alignItems:'center'}}>
                        <View style={{flex:1, marginLeft:10}}>
                            <Text style={GSS.mainInfoText}>{item.display_name} 파티장으로 이용중</Text>
                        </View>
                        <TouchableOpacity  onPress={SettingUsedItem}>
                            <Image
                                source={SettingImage}
                                style={[GSS.mainItemImage, {marginRight:10, height:25, width:25}]}     
                            />
                        </TouchableOpacity>
                    </View>
                </View>
                { item.item_id === 1 && (
                    <View style={[GSS.mainViewBox, {borderWidth:1}]}>
                        <View style={{marginLeft:10, marginBottom:10}}>
                            <Text style={GSS.mainDefaultText}>추가회원 파티</Text>
                        </View>  
                        { user_item.maximum_usage2 > 0 && (
                            <DisplayNetflix/>
                        )}
                        {isLoading && (
                            <View style={GSS.activityIndicatorContainer}>
                                <ActivityIndicator size='large' color="#0000ff" />
                            </View>
                        )}                          
                        { userAccounts.length < 2 && (
                            <TouchableOpacity
                                style={{ flexDirection:'row', justifyContent:'space-between', alignItems:'center',}}
                                onPress={() => {AddNetNetflix()}}
                            >
                                <Text style={[GSS.mainInfoText, {marginLeft:10}]}> 추가회원 추가하기</Text>
                                <Image
                                    source={nextImage}
                                    style={{width:40, height:40}}
                                />
                            </TouchableOpacity>                                       
                        )}
                    </View>
                    )}
                { user_item.maximum_usage > 0  && (
                <React.Fragment>
                    <View style={[GSS.mainViewBox, {flex:1}]}>
                        { user_item.item_id === 1 && (
                            <View style={{marginLeft:10, marginBottom:10}}>
                                <Text style={GSS.mainDefaultText}>일반회원 파티</Text>
                            </View>  
                        )}
                        <View style={{flexDirection:'row', justifyContent: 'space-between',position: 'relative', alignItems:'center'}}>
                            <DisplayParty/>
                        </View>
                    </View>
                </React.Fragment>
                )}
                <View style={[GSS.mainViewBox, {}]}>
                    <View style={{padding:5, marginBottom:10, flexDirection:'row', justifyContent: 'space-between'}}>
                        <Text style={GSS.mainInfoText}>{item.display_name} 아이디/비밀번호</Text>
                        <TouchableOpacity  onPress={changeIdPwd}>
                            <Text style={[GSS.mainDefaultText, {textDecorationLine: 'underline'}]}>변경</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={[SS.textContainer, {marginBottom:10}]}>
                        <View style={{width:50}}>
                            <Text style={GSS.mainDefaultText}>아이디</Text>
                        </View>
                        <View style={{flex:1, flexDirection:'row', alignItems:'center'}}>
                            <Text style={GSS.mainInfoText}>{checkDisplayID ? account_id : '●●●●●●●●'}</Text>
                            <TouchableOpacity  onPress={() => copyToClipboard(account_id)}>
                                <Image
                                    source={CopyImage}
                                    style={{width:20, height:20, marginLeft:5}}
                                />
                            </TouchableOpacity>
                        </View>
                        <TouchableOpacity  onPress={showDisplayID}>
                            <Text style={GSS.mainInfoText}>표시</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={SS.textContainer}>
                        <View style={{width:50}}>
                            <Text style={GSS.mainDefaultText}>비번</Text>
                        </View>
                        <View style={{flex:1, flexDirection:'row', alignItems:'center'}}>
                            <Text style={GSS.mainInfoText}>{checkDisplayPWD ? account_pwd : '●●●●●●●●'}</Text>
                            <TouchableOpacity  onPress={() => copyToClipboard(account_pwd)}>
                                <Image
                                    source={CopyImage}
                                    style={{width:20, height:20, marginLeft:5}}
                                />
                            </TouchableOpacity>
                        </View>
                        <TouchableOpacity onPress={showDisplayPWD}>
                        <Text style={GSS.mainInfoText}>표시</Text>
                        </TouchableOpacity>
                    </View> 
                </View>       
                {/* <View style={GSS.mainViewBox}>
                    <TouchableOpacity
                        style={{ flexDirection:'row', justifyContent:'space-between', alignItems:'center',}}
                        onPress={() => {ChangeAccount()}}
                    >
                        <Text style={[GSS.mainInfoText, {marginLeft:10}]}> 마이샷 이메일로 계정 변경</Text>
                        <Image
                            source={nextImage}
                            style={{width:40, height:40}}
                        />
                    </TouchableOpacity>
                    <View style={{marginLeft:10}}>
                        <Text style={[GSS.mainSmallText]}>★ 계정관리를 쉽고 편하게 하세요</Text>
                    </View>
                </View> */}
            </View>
            <View style={GSS.mainViewBox}>
                    <View style={{marginLeft:10, marginTop:10}}>
                        <TouchableOpacity 
                            onPress={goOTTSite}
                            style={{ flexDirection: 'row', lignItems: 'center' }}
                        >
                            <Text style={[GSS.mainInfoText]}>{item.display_name} 바로가기</Text>
                            <Image
                                source={LinkImage}
                                style={{width:25, height:25, marginLeft:5}}
                            />            
                        </TouchableOpacity>    
                    </View> 
                </View>               
        </ScrollView>
    );
};


const SS = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    titleContainer: {
        alignItems:'center', 
        justifyContent:'center', 
        marginBottom:10, 
        padding:5, 
        backgroundColor:'#eaebed',
        borderRadius:5
    },
    actionContainer: {
        alignItems:'center', 
        justifyContent:'center', 
        marginTop:10, 
        padding:5, 
        borderRadius:5,
        borderWidth: 1,
        borderColor: '#00e5e0'
    },    
    emptyContainer: {
        alignItems:'center', 
        justifyContent:'center', 
        marginTop:10, 
        padding:5, 
    },    
    iconContainer: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '100%',
    },
    iconWrapper: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    icon: {
        width: 50, // 아이콘 크기에 맞게 조정하세요
        height: 50, // 아이콘 크기에 맞게 조정하세요
        marginBottom: 5,
        borderRadius:5
    },
    textContainer: {
        flexDirection:'row', 
        alignItems:'center',
        padding:10, 
        borderWidth:1,
        borderRadius:5,
        borderColor: '#8F8C83'
    }
  });
export default DisplayUserItem;