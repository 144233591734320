import React, {useState, useEffect, useRef} from 'react';
import { Image, Text, View, StyleSheet, Dimensions, TouchableOpacity, ActivityIndicator, Button } from 'react-native';
import GSS from '../GlobalStyleSheet2';
import CloseHeader from '../CloseHeader';
import { useModal } from '../ModalContext';
import { GetNewEmail, SetSyncEmailID2, GetAccountEmailofDB2, ChangeUserAccount} from '../api';
import { useUserItem } from '../UserItemContext';
import { generateRandomPassword } from '../EmailAccount.js';

import * as Clipboard from 'expo-clipboard';
import { WebView } from 'react-native-webview';

function ChangeMyshottAccount ({navigation, route}) {
    const { user_item, item, OTT } = route.params;
    const { confirmModal, showMessage, waitMessage, closeModal } = useModal();
    const [isLoading, setIsLoading] = useState(false);
    const [ email, setEmail] = useState();
    const [ pwd, setPwd] = useState();
    const [ myAccountId, setMyaccountId] = useState();
    const [emailID, setEmailID] = useState();

    const [ accountName, setAccountName] = useState();
    const [ curStep, SetCurStep] = useState(0);
    const [ stepTitle, SetStepTitle] = useState('');

    const [ source , setSource] = useState({ uri: OTT.loginUrl, webSecurityEnabled: false });
    const [source2, setSource2] = useState({ html: '<html><body>이메일을 확인 중...</body></html>' });
    const [currentUrl, setCurrentUrl] = useState(null); // 현재 URL 상태
    const webviewRef = useRef(null);
    const webviewRef2 = useRef(null);
    const [canGoBack, setCanGoBack] = useState(false);
    const [canGoForward, setCanGoForward] = useState(false);
    const [ isCheckingEmail, setIsCheckingEmail] = useState(false);
    let waitModalId;
    const [isActive, setIsActive] = useState(true);   
    const [ stepButtonTitle, setStepButtonTitle] = useState('...');
    const { ReadUserItem } = useUserItem(); 
    const _STEP = [
        {   // 0
            step_title : `${OTT.name}에 로그인해 주세요`,
            step_url : OTT.loginUrl,
        },
        {   // 1
            step_title : '로그인을 진행합니다',
            step_url : 'https://www.disneyplus.com/identity/login/enter-password',
        },
        {   // 2
            step_title : '회원 정보 수정을 진행합니다',
            step_url : 'https://www.disneyplus.com/ko-kr/get-app'
        },
        {   // 3, 
            step_title : '회원 정보 수정을 진행합니다',
            step_url : 'https://www.disneyplus.com/ko-kr/account'
        },
        {   // 4. 
            step_title : '회원 정보 수정을 진행합니다',
            step_url : ''            
        },
        {   // 5.
            step_title : '이메일(아이디) 변경을 진행합니다',
            step_url : 'https://www.disneyplus.com/ko-kr/account/change-email'            
        },
        {   // 6. 
            step_title : '이메일(아이디) 변경을 진행합니다',
            step_url : 'xxxxxxxxxxxxxx'            
        },
        {   // 7. 
            step_title : '이메일(아이디) 변경을 진행합니다',
            step_url : 'https://member.wavve.com/mobile/me/info?token='            
        },    
        {   // 8. 
            step_title : '이메일(아이디) 변경이 완료되었습니다',
            step_url : 'https://member.wavve.com/mobile/me/info?token='            
        }            
    ];

    const onNavigationStateChange = async (navState) => {
        try {
            setCanGoBack(navState.canGoBack);
            setCanGoForward(navState.canGoForward);
            if (currentUrl !== navState.url) {
                // 새로운 URL로 변경됨
                // console.log(`URL 변경됨(${curStep}):[${navState.url}]`);
                setCurrentUrl(navState.url); // 현재 URL 상태 업데이트
                // 여기에 추가 로직을 구현할 수 있습니다.
                if( navState.url === _STEP[0].step_url) {
                    SetCurStep(1);
                    if(user_item.account_userid ) {
                        await copyToClipboard(user_item.account_userid);
                        showMessage([
                            `${OTT.name} 계정 [${user_item.account_userid}]을 복사하였습니다.`,
                            `이메일 주소란에 붙여넣기 하시고 '다음'을 클릭하세요`,
                        ]);
                    } else {
                        showMessage([
                            `계정 이메일을 입력하시고${OTT.name} '다음'을 클릭하세요`
                        ]);                        
                    }
                } else if( navState.url === _STEP[1].step_url) {
                    SetCurStep(2);
                    if(user_item.account_pwd ) {
                        await copyToClipboard(user_item.account_pwd);
                        showMessage([
                            `${OTT.name} 비밀번호[${user_item.account_pwd}]를 복사하였습니다.`,
                            `비밀번호란에 붙여넣기 하시고 '로그인'을 클릭하세요`,
                        ]);
                    } else {
                        showMessage([
                            `계정 비번을 입력하시고${OTT.name} '로그인'을 클릭하세요`
                        ]);                        
                    }
                } else if( navState.url === _STEP[2].step_url) {
                    SetCurStep(3);                    
                    setSource({ uri: _STEP[3].step_url, webSecurityEnabled: false });
                } else if( curStep===3 && navState.url === _STEP[3].step_url) {
                    SetCurStep(5);
                    setSource({ uri: _STEP[5].step_url, webSecurityEnabled: false });
                    const ret = await GetAccountEmailofDB2(user_item.user_id, user_item.item_id, user_item.user_item_id);
                    if( ret.success) {
                        setEmail(ret.data.email);
                        setMyaccountId(ret.data.myaccount_id);
                        setPwd(generateRandomPassword());
                    }                
                } else if( navState.url === _STEP[5].step_url) {
                    SetCurStep(6);
                    await copyToClipboard(email);
                    showMessage([
                        `${OTT.name} 새로운 계정 [${email}]을 복사하였습니다.`,
                        `새로운 이메일 란에 붙여넣기 하시고 '저장'을 클릭 하세요`,
                    ]);                       

                } else if( navState.url.includes(_STEP[7].step_url)) {
                    SetCurStep(8);
                    const ret = await SetSyncEmailID2(myAccountId);
                    if( ret.success) {
                        setEmailID(ret.data);
                    }
                    await copyToClipboard(email);
                    showMessage([
                        `${OTT.name} 새로운 계정 [${email}]을 복사하였습니다.`,
                        `이메일 주소란에 붙여넣기 하시고 '인증코드 전송'을 클릭 하세요`,
                        //'인증코드 이메일을 받는데 약간의 시간이 소요될 수 있습니다'
                        '인증코드 전송을 클릭하 오른쪽 아래에 다음버튼을 클릭하세요'
                    ]);   
                    setStepButtonTitle('다음');
                    setIsActive(true);
                } else if( curStep === 7 && navState.url.includes(_STEP[8].step_url)) {
                    SetCurStep(9);
                    setIsCheckingEmail(false);
                    const ret = await ChangeUserAccount(user_item.user_item_id, email);
                    if( ret.success) {
                        await ReadUserItem(user_item.user_id);
                        setIsActive(true);
                        setStepButtonTitle('완료');
                        showMessage([
                            '감사합니다. 성공적으로 이메일 계정이 변경되었습니다',
                            `'완료' 버튼을 클릭하세요`
                        ]);
                        
                    } else {
                        setIsActive(true);
                        setStepButtonTitle('취소');
                        showMessage([
                            '죄송합니다',
                            `새로운 ${OTT.name} 계정 '${email}'을 저장하지 못했습니다.`,
                            '새로운 계정을 기록해 두시고 관리자에게 문의해 주세요.'
                        ]);
                    }
                }
            }
        } catch(error) {
            console.log(error);
        }
     };

    const goBack = () => {
        webviewRef.current.goBack();
    };

    const goForward = () => {
        webviewRef.current.goForward();
    };       

    const _completeProc = async () => {
        try {
        } catch(error) {
            console.log(error);
        }
    }

    const CloseButton = () => {
        navigation.goBack();
    };    

    const copyToClipboard = async (text) => {
        await Clipboard.setStringAsync(text);
        //showToast('클립보드에 복사되었습니다.');
    };    

    // 10 초마다 새로운 이메일을 확인한다
    const [timerId, setTimerId] = useState(null);
    const [countdown, setCountdown] = useState(10);

    useEffect(() => {
        return () => {
            if (timerId) {
                clearInterval(timerId);
            }
        };
    }, [timerId]);


    useEffect(() => {
        const emailCheck = async () => {
            try {
                const ret = await GetNewEmail(email, emailID);
                if (ret.success) {
                    setIsLoading(false);
                    setStepButtonTitle('...');
                    setIsActive(false);                
                    stopEmailCheck();
                    //closeModal(waitModalId);
                    setIsCheckingEmail(true);
                    setSource2({ html: ret.data.body, webSecurityEnabled: false });
                    setTimeout(() => {
                        showMessage('화면에 보이는 인증코드를 인증코드 입력란에 입력하시고 확인을 클릭하세요');
                      }, 500); // 100ms 후에 showMessage 호출                    
                } else {
                    setCountdown(10); // 카운트다운을 다시 10초로 리셋
                }
            } catch (error) {
                console.error(error);
                // 에러 핸들링 로직 추가
            }
        };

        if (countdown === 0) {
            emailCheck();
        }
    }, [countdown]);

    const startCheckEmail = () => {
        if (!timerId) {
            const id = setInterval(() => {
                setCountdown(prevCount => prevCount > 0 ? prevCount - 1 : 0);
            }, 1000);
            setTimerId(id);
            setCountdown(10);
        }
    };
    
    const stopEmailCheck = () => {
        if (timerId) {
            clearInterval(timerId);
            setTimerId(null);
        }
    };
      
    const completeProc = () => {
        if (curStep === 7) {
            setIsLoading(true);
            setStepButtonTitle('대기');
            startCheckEmail(); 
            //waitModalId = waitMessage('인증코드 이메일을 확인 중입니다... 잠시만 기다려 주세요');      
            return;
        }
        if (curStep === 8) {
            stopEmailCheck(); // 이메일 체크 중단
            navigation.goBack();
            return;
        }        
    };

    const onWebViewLoad = () => {
        const script = `window.scrollTo(0, 500);`;
        webviewRef2.current.injectJavaScript(script);
    };

    const windowWidth = Dimensions.get('window').width * 0.8;
    return (
        <View style={GSS.mainContainer}>
            <CloseHeader
                closeAction={CloseButton}
            >
                <Text style={GSS.mainDefaultTextB}>{OTT.name} 계정 변경</Text>
            </CloseHeader>
            <View style={[GSS.mainViewBoxNoBorder, {marginTop:5}]}>
                <Text style={[GSS.mainDefaultTextB]}>
                    {_STEP[curStep].step_title}
                </Text>
            </View>
            <View style={{flexDirection:'row'}}>
                <View style={{flex:1, borderWidth:0.25, alignItems:'center', justifyContent:'center'}}>
                    <TouchableOpacity onPress={goBack} disabled={!canGoBack}>
                        <Text style={[GSS.mainDefaultText, {color: canGoBack ? '#291B44' : '#c4c4c4'}]}>{'<< 뒤로'}</Text>
                    </TouchableOpacity>
                </View>
                <View style={{flex:1, borderWidth:0.25, alignItems:'center', justifyContent:'center'}}>
                    <TouchableOpacity onPress={goForward} disabled={!canGoForward}>
                        <Text style={[GSS.mainDefaultText, {color: canGoForward ? '#291B44' : '#c4c4c4'}]}>{'앞으로 >>'}</Text>
                    </TouchableOpacity>
                </View>
            </View>
            <WebView 
                ref={webviewRef}
                onNavigationStateChange={onNavigationStateChange}
                style={{flex:1}}
                //scalesPageToFit={false}
                source={source}
                originWhitelist={['*']}
            />
            { isCheckingEmail && (
            <WebView 
                ref={webviewRef2}
                style={{flex:1}}
                //scalesPageToFit={false}
                source={source2}
                originWhitelist={['*']}
                onLoad={onWebViewLoad}
            />
            )}
            <View style={{flexDirection:'row', marginTop:5}}>
                <View style={{flex:1}}>
                    <View style={[SS.textContainer]}>
                        { (curStep > -1 && curStep < 6) && (
                            <React.Fragment>
                                <View style={{flexDirection:'row'}}>
                                    <View style={{width:45}}>
                                        <Text style={GSS.mainDefaultText}>계정</Text>
                                    </View>
                                    <View style={{flex:1, }}>
                                        <Text style={GSS.mainInfoTextB}>{user_item.account_userid}</Text>
                                    </View>
                                    <TouchableOpacity style={{flex:0.2}}  onPress={() => copyToClipboard(user_item.account_userid)}>
                                        <Text style={GSS.mainInfoText}>복사</Text>
                                    </TouchableOpacity>
                                </View>
                                <View style={{flexDirection:'row', marginBottom:5}}>
                                    <View style={{width:45}}>
                                        <Text style={GSS.mainDefaultText}>비번</Text>
                                    </View>
                                    <View style={{flex:1, }}>
                                        <Text style={GSS.mainInfoTextB}>{user_item.account_pwd}</Text>
                                    </View>
                                    <TouchableOpacity style={{flex:0.2}}   onPress={() => copyToClipboard(user_item.account_pwd)}>
                                        <Text style={GSS.mainInfoText}>복사</Text>
                                    </TouchableOpacity>
                                </View>               
                            </React.Fragment>
                        )}
                        { curStep === 6 && (
                            <React.Fragment>
                                <View style={{flexDirection:'row'}}>
                                    <View style={{width:45}}>
                                        <Text style={GSS.mainDefaultText}>이메일</Text>
                                    </View>
                                    <View style={{flex:1, }}>
                                        <Text style={GSS.mainInfoTextB}>{email}</Text>
                                    </View>
                                    <TouchableOpacity style={{flex:0.2}} onPress={() => copyToClipboard(email)}>
                                        <Text style={GSS.mainInfoText}>복사</Text>
                                    </TouchableOpacity>
                                </View>
                            </React.Fragment>
                        )}                        
                        { curStep === 7 && (
                            <React.Fragment>
                                <View style={{alignItems:'center', justifyContent:'center'}}>
                                    <Text style={GSS.mainInfoTextB}>{`인증코드 전송을 클릭한 후 다음을 클릭(☞)`}</Text>
                                </View>
                            </React.Fragment>
                        )} 
                        { isLoading  && (
                            <React.Fragment>
                                <View style={{alignItems:'center', justifyContent:'center'}}>
                                    <Text style={GSS.mainInfoTextB}>{`이메일을 확인 중입니다...`}</Text>
                                </View>
                            </React.Fragment>
                        )}                                                  
                    </View>
                </View>
                {isLoading && (
                <View style={GSS.activityIndicatorContainer}>
                 <ActivityIndicator size='large' color="#0000ff" />
                </View>
                )}                    
                <View style={{marginLeft:5, alignItems:'center', justifyContent:'center', flex:0.2, borderWidth:0.5, borderRadius:10,
                    backgroundColor: (!isActive || stepButtonTitle==='...') ? '#eFeCe3' : '#017cf7'}}>
                    <TouchableOpacity 
                        disabled={!isActive || stepButtonTitle === '...'} 
                        onPress={completeProc}
                    >
                        <Text style={[GSS.mainBigText, {color:'#ffffff'}]}>{isActive ? stepButtonTitle : `${countdown}`}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>   
    );
};

const SS = StyleSheet.create({
    container: {
      // 컨테이너를 flex 컨테이너로 설정합니다.
      flex: 1,
      justifyContent: 'flex-end', // 자식 요소들을 컨테이너의 오른쪽으로 정렬합니다.
      alignItems: 'center', // 자식 요소들을 컨테이너의 중앙으로 정렬합니다(수직 방향).
      borderWidth:1,
      borderColor : '#8F8C83',
      margin:5,
      borderRadius:10,
      marginBottom : 15
    },
    confirmView: {
        //flex: 
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems : 'center',
        marginBottom : 10
    },
    textContainer: {
        alignItems:'center',
        padding:10, 
        borderWidth:1,
        borderRadius:5,
        borderColor: '#8F8C83'
    }
});

export default ChangeMyshottAccount;