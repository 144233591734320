import React, {useRef, useEffect, useState} from 'react';
import { StyleSheet, Text, View, BackHandler , TouchableOpacity ,Linking, Platform} from 'react-native';
import { useIsFocused, useFocusEffect } from '@react-navigation/native';
import { useAuth, useEnv } from '../AppContext';
import { ScrollView } from 'react-native-gesture-handler';
import HomeOfLogin from './HomeOfLogin';
import HomeOfLogout from './HomeOfLogout';
import { showToast } from '../Global';
import { useNavigation } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { Helmet } from 'react-helmet-async';

const DOUBLE_PRESS_DELAY = 2000; 

function Home ({navigation, route}) {
  const { isLoggedIn } = useAuth();
  const lastBackPressed = useRef(Date.now());

  useFocusEffect(
    React.useCallback(() => {
      if(Platform.OS === 'web') {
        return;
      }
      const onBackPress = () => {
        const timeNow = Date.now();
        if (timeNow - lastBackPressed.current < 2000) {
          BackHandler.exitApp(); // 앱 종료
          return true; // 이벤트 소비
        } else {
          lastBackPressed.current = timeNow;
          showToast('한 번 더 누르면 종료됩니다.');
          return true; // 이벤트 소비
        }
      };
  
      BackHandler.addEventListener('hardwareBackPress', onBackPress);
      return () => {
        BackHandler.removeEventListener('hardwareBackPress', onBackPress);
      };
    }, []) // 여기서는 의존성 배열을 비워둡니다.
  );

  // useEffect(() => {
  //   let isMounted = true; // 컴포넌트가 마운트 상태인지 추적합니다.

  //   // async 함수를 정의하고 즉시 호출합니다.
  //   (async () => {
  //     try {
  //       //const sysData = await LoadFromSystem();
  //       // 컴포넌트가 여전히 마운트 상태인 경우에만 상태를 설정하거나 로깅을 수행합니다.
  //       if (isMounted) {
  //         //console.log(sysData.kakao_id);
  //         //console.log(ENV);
  //         if(!ENV || !ENV.t_item) {
  //           const sysData = await LoadFromSystem();
  //           const newENV = {};
  //           if(sysData && sysData.user_id ) {
  //               newENV.user_id = sysData.user_id;
  //               newENV.last_login_type =sysData.last_login_type;
  //               // newENV.kakao_id = sysData.kakao_id;
  //               newENV.message_ymd = sysData.message_ymd;
  //               newENV.t_item = sysData.t_item;
  //               newENV.item_check_time = sysData.item_check_time;
  //               await loginProc(newENV);
  //           }            
  //         }
  //       }
  //     } catch (e) {
  //       // 오류를 적절하게 핸들링합니다. (예: 로깅, 사용자에게 메시지 표시 등)
  //       console.error(e);
  //     }
  //   })();

  //   // 컴포넌트 언마운트 시 isMounted를 false로 설정합니다.
  //   return () => {
  //     isMounted = false;
  //   };
  // }, [ENV]);   

  const openURL = async (url) => {
    await WebBrowser.openBrowserAsync(url);
    //window.location.href = url;
  }; 
  
  const DisplayContent = () => {
    if(isLoggedIn) {
      return (
        <HomeOfLogin/>
      );
    } else {
      return (
        <HomeOfLogout/>
      );
    }    
  } 
  
  const makeCall = (phoneNumber) => {
    const url = `tel:${phoneNumber}`;
    Linking.canOpenURL(url)
      .then((supported) => {
        if (!supported) {
          console.log('Can\'t handle url: ' + url);
        } else {
          return Linking.openURL(url);
        }
      })
      .catch((err) => console.error('An error occurred', err));
  };  

  const MainContent = () => {
    return (
      <ScrollView style={styles.container}>
        <View style={{flex:1}}>
          <DisplayContent/>
        </View>
        <View style={{justifyContent: 'center', backgroundColor: '#291B44', padding: 10}}>
          <View style={{marginTop: 25,}}>
            <Text style={styles.aboutTitle}>주식회사 웹프리즘</Text>
            <Text style={styles.aboutText}>대표 : 한지은</Text>
            <Text style={styles.aboutText}>주소 : 경기도 안양시 동안구 시민대로 230, 평촌아크로타워 B동 406-1호</Text>
            <Text style={styles.aboutText}>통신판매번호 : 2023-안양동안-1849</Text>
            <Text style={styles.aboutText}>사업자등록번호 : 439-81-00075</Text>
            <Text style={styles.aboutText}>
              대표번호 : 
              <Text onPress={() => makeCall("031-478-9406")} style={{color:'#F7F7F7', textDecorationLine: 'underline'}}>
                031-478-9406
              </Text>
            </Text>
            <View style={{flexDirection:'row'}}>
              <Text style={styles.aboutText}>제휴문의 : </Text>
              <TouchableOpacity onPress={() => openURL('mailto:myshott406@gmail.com')}>
                <Text style={[styles.aboutText, {textDecorationLine: 'underline'}]}>myshott406@gmail.com</Text>
              </TouchableOpacity>
            </View>
          </View>
          <View style={{flexDirection: 'row', marginTop:15, paddingHorizontal: 20, justifyContent: 'space-between', alignItems: 'center' }}>
            <TouchableOpacity onPress={() => openURL('https://myshott.co.kr/UserAgreement.html')}>
              <Text style={[styles.aboutText, {flex: 1, textAlign: 'center', textDecorationLine: 'underline'}]}>이용약관</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => openURL('https://myshott.co.kr/PrivacyPolicy.html')}>
              <Text style={[styles.aboutText, {flex: 1, textAlign: 'center', textDecorationLine: 'underline'}]}>개인정보처리방침</Text>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
    );   
  }

  if( Platform.OS === 'web') {
    return (
      <React.Fragment>
        <Helmet>
          <title>마이샷 - 넷플릭스,티빙,웨이브,디지니+ OTT계정공유</title>
          <meta name="description" content="마이샷 - OTT 계정공유, 넷플릭스, 티빙, 웨이브, 디즈니+ 등 최대 75% 할인된 금액으로 시청" />
          <meta property="og:description" content="마이샷 - OTT 계정공유, 넷플릭스, 티빙, 웨이브, 디즈니+ 등 최대 75% 할인된 금액으로 시청" />
          <meta property="og:title" content="마이샷 - 넷플릭스,티빙,웨이브,디지니+ OTT계정공유" />
          <meta property="og:image" content="https://myshott.co.kr/og_image.png" />
          <meta name="keywords" content="마이샷,OTT,OTT 공유,피클플러스,넷플릭스,디지니,티빙,웨이브,왓챠" />
        </Helmet>       
        <MainContent/>
      </React.Fragment>
    );
  } else {
    return (
      <MainContent/>
    );
  }
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },  
  aboutText: {
    color : '#8F8C83',
    marginBottom: 3
  },
  aboutTitle: {
    color : '#F7F7F7',
    fontWeight : 'bold',
    fontSize : 16,
    marginBottom: 15
  }
});

export default Home;