import React, {useState, useEffect, useMemo, useRef } from 'react';
import { Text, View, TouchableOpacity , Image, StyleSheet, ScrollView, Platform, Linking, AppState} from 'react-native';
import { useAuth, useEnv, GetOTTByName} from '../AppContext';
import { LinearGradient } from 'expo-linear-gradient';
import GSS from '../GlobalStyleSheet2'
import CurvedBorder from '../CurvedBorder';
import ProgressButton, {ProgressButtonOrg} from '../ProgressButton';
import addImage from '../../assets/add.png';
import { useUserItem } from '../UserItemContext';
import { useNavigation  } from '@react-navigation/native';
import nextImage from '../../assets/next.png';
import * as Animatable from 'react-native-animatable';
import nextImageW from '../../assets/nextW.png';
import { SplitNickName, targetOS, truncateString } from '../Global';
import RefreshImage from '../../assets/refresh.png';
import Message from '../../assets/message.png';
import { useModal } from '../ModalContext';
import nextBImage from '../../assets/nextB.png';
import prevImage from '../../assets/prev.png';
import prevBImage from '../../assets/prevB.png';
import { UpdateUserMessageToDB, GetTodos, SetLinkInfo, DeleteTodo, CheckCertiIDOfDB, SetAppStatus, GetAccountEmail } from '../api';
import { useFocusEffect } from '@react-navigation/native';
import AlertImage from '../../assets/alert.png';
import LinkImage from '../../assets/chain.png';
import { Share } from 'react-native';
import CheckNotifyError from './CheckNotifyError';
import { appVersion } from '../Global';
import ShareLinkImage from '../../assets/share_link.png'
import infoImage from '../../assets/info_right.png';
import _ from 'lodash';

const HomeOfLogin = React.memo(function HomeOfLogin() {
    const { isLoggedIn } = useAuth();
    const { ENV, getItemById } = useEnv();
    const { UserItem, ReadUserItem } = useUserItem();
    const [ shareCount, setShareCount ] = useState(0);
    const [ waitingCount, setWaitingCount ] = useState(0);
    const intervalSizeProfile = 65;
    const [ savingCost, setSavingCost] = useState(0);
    const [ totalSaveCost, setTotalSaveCost] = useState(0);
    const navigation = useNavigation();
    const { openModal, closeModal, showMessage, confirmModal, showModalMessage } = useModal();
    let msgViewerModalID;
    const [ netflixUserItem, setNetflixUserItem ] = useState(null);
    const [ inviteCode, setInviteCode] = useState();
    const checkedErrorItems = useRef(new Map());
    const [appState, setAppState] = useState(AppState.currentState);
    const previousAppState = useRef(appState.current);

    // 넷플릭스 파티원의 초대메일 정보
    const [emailAccountInfo, setEmailAccountInfo]  = useState(null);

    function _SetAppStatus(status) {
        try {
            if( ENV?.user_id) {
                //console.log('app status:', status);
                SetAppStatus(status, ENV.user_id);
                // if( ret.success ) {
                //     console.log(ret.data);
                // }
            }
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        //console.log(ENV?.user_id, '앱이 시작되었습니다. 초기 상태는 active입니다.');
        _SetAppStatus(true);
    }, []);; 
    
    useEffect(() => {
        if (Platform.OS === 'web') {
            const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                //console.log('웹 페이지가 활성화되었습니다.');
                _SetAppStatus(true);
            } else {
                //console.log('웹 페이지가 비활성화되었습니다.');
                _SetAppStatus(false);
            }
            };

            document.addEventListener("visibilitychange", handleVisibilityChange);

            return () => {
                document.removeEventListener("visibilitychange", handleVisibilityChange);
            };

        } else {
            const handleAppStateChange = nextAppState => {
                if (appState.match(/inactive|background/) && nextAppState === 'active') {
                    // 앱이 포그라운드로 돌아올 때 타이머 초기화
                    //console.log(ENV?.user_id, '포그라운드로 전환');
                    _SetAppStatus(true);
                } else if (nextAppState === 'background') {
                    // 앱이 백그라운드로 전환될 때 타이머 설정
                    //console.log(ENV?.user_id, '백그라운드로 전환');
                    _SetAppStatus(false);
                }
                setAppState(nextAppState);
            };

            const subscription = AppState.addEventListener('change', handleAppStateChange);

            return () => {
                if (subscription.remove) {
                    subscription.remove();
                } else if (typeof subscription === 'function') {
                    subscription(); // 최신 React Native 버전에서는 이 방식을 사용
                }
                clearTimeout(this.logoutTimer);
            };
        }
    }, [appState]);;
   

    useEffect(() => {
        (async () => {
            try {
                //console.log(ENV);
                // ENV 또는 ENV.t_item이 null 인경우  다시 로딩 하는걸 생각해 보자.  프로그램을 완정 종료 후 재 시작 하면 됨 
                // console.log(ENV.user_id);
                // console.log(ENV.t_item);
                // if( ENV && !ENV.t_item) {
                // }

                let cnt = 0;
                let cost = 0;
                if( UserItem.t_user_item && UserItem.t_user_item.length > 0) {
                    cnt += UserItem.t_user_item.length;
                    UserItem.t_user_item.forEach(userItem => {
                        const item = getItemById(userItem.item_id);
                        cost += (item.monthly_price * userItem.maximum_usage);
                        if (!checkedErrorItems.current.has(userItem)) {
                            if( userItem.error_cnt > 0 ) {
                                checkedErrorItems.current.set(userItem, {checked: true});
                            }                          
                        }                  
                    });
                }
                if( UserItem.t_used_item && UserItem.t_used_item.length > 0) {
                    cnt += UserItem.t_used_item.length;
                    UserItem.t_used_item.forEach(usedItem => {
                        const item = getItemById(usedItem.item_id);
                        if( usedItem.item_id === 1) {
                            cost += 8000;
                        } else {
                            cost += (item.monthly_price * 3);   
                        }
                    });                     
                }

                setWaitingCount(UserItem.t_waiting_list.length);             
                setShareCount(cnt);
                setSavingCost(cost);  

            } catch (e) {
                // 오류를 적절하게 핸들링합니다. (예: 로깅, 사용자에게 메시지 표시 등)
                console.error(e);
            }
        })();
    
    }, [UserItem.t_user_item, UserItem.t_used_item, UserItem.t_waiting_list, ENV.user_id]);

    useFocusEffect (
        React.useCallback(() => {
            (async () => {
                try {
                    for(const usedItem of UserItem.t_used_item) {
                        //console.log( usedItem);
                        if( usedItem.item_id === 1 && usedItem.using_status === 1 && usedItem.account_email_id) {
                            const ret = await GetAccountEmail(usedItem.account_email_id);
                            if(ret.success) {
                                // 넷플릭스 초대 회원 수락을 해야 하는 경우
                                if( ret.data.last_status === 2) {
                                    //console.log( ret.data);
                                    const _info = {
                                        used_item : usedItem,
                                        email_account : ret.data
                                    }
                                    setEmailAccountInfo(_info);
                                }
                            } else {
                                showMessage('추가회원 계정 정보를 확인할 수 없습니다. 관리자에게 문의 하세요');
                            }
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
          })();          
        }, [UserItem.t_used_item])
    );   

    useEffect(() => {
        for (let [key, value] of checkedErrorItems.current.entries()) {
            if( value.checked === true) {
                //console.log(key);
                confirmCheckingError(key);
            }
        }
    }, []);

    function compareVersions(version1, version2) {
        const parts1 = version1.split('.').map(Number);
        const parts2 = version2.split('.').map(Number);
      
        for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
          const part1 = parts1[i] || 0; // 비교를 위해 undefined 부분을 0으로 처리
          const part2 = parts2[i] || 0; // 비교를 위해 undefined 부분을 0으로 처리
      
          if (part1 > part2) return 1; // version1이 version2보다 크다
          if (part1 < part2) return -1; // version1이 version2보다 작다
        }
      
        return 0; // 버전이 같다
    }

    const appUpdate = () => {
        const appStoreUrl = "market://details?id=com.myshott.eva";
        Linking.openURL(appStoreUrl).catch(err => {
            console.error('Failed to open URL:', err);
        }); 
    }
    
    const checkedTodo = useRef(false);
    const [ checkedUpdate, setCheckedUpdate] = useState(false);
    useEffect(() => {
        (async () => {
            try {
                if (!checkedTodo.current) {
                    checkedTodo.current = true;
                    const ret = await GetTodos(ENV.user_id);
                    if( ret.success ) {
                        for(const todo of ret.data.todos) {
                            let success;
                            //console.log(todo);
                            if(todo.todo_type === 1) {
                                const ret2 = await SetLinkInfo(todo.todo_param, ENV.user_id);
                                if( ret2.success ) {
                                    success = true;
                                    await ReadUserItem(ENV.user_id);    
                                } else {
                                    showMessage(ret2.error.message);
                                }
                            }
                            if(success === true) {
                                await DeleteTodo(todo.todo_id);
                            }
                        }
                        //console.log(ret.data.app_version, appVersion);
                        const result = compareVersions(appVersion, ret.data.app_version );
                        if (result === -1) {
                            //console.log('업데이트가 필요합니다');
                            if( Platform.OS !== 'web') {
                                console.log('update reuslt : ', result);
                                setCheckedUpdate( true);
                            }
                        } 
                    }
                }
            } catch (e) {
                // 오류를 적절하게 핸들링합니다. (예: 로깅, 사용자에게 메시지 표시 등)
                console.error(e);
            }
        })();
    }, []);

    useEffect(() => {
        if( checkedUpdate === true) {
            const _confirm = {
                afterOK : appUpdate,
                body : '마이샷 앱이 새로운버젼이 있습니다. 업데이트를 진행해 주세요'
            };
            confirmModal(_confirm); 
            setCheckedUpdate(false);          
        }
    }, [checkedUpdate]);   
    
    const ShowProfile = ({ nicknames, profileIamge, startpos=0, used_item=false }) => {
        if (!Array.isArray(nicknames)) {
            console.error('nicknames is not an array:', nicknames);
            return null;
        }
        if(!nicknames.length) return null;
        // console.log(nicknames.length);
        // console.log(nicknames);
        let pos = startpos;
        return nicknames.map((nickname, index) => {
            const key = index + 60;
            pos += intervalSizeProfile;
            //console.log(pos);
            let nick = '';
            if( used_item ) {
                nick = SplitNickName(nickname).nick_name;
            } else {
                nick = nickname;
            }
            const messages = UserItem.t_user_message.filter(obj => obj.is_host_message === 0 && obj.from_nick_name === nick);
            return (
                <View key={key} style={{justifyContent: 'center', alignItems: 'center', position:'absolute', left:pos}}>
                    <View style={[SS.avataContainer, {backgroundColor: used_item ? '#F7F7F7' : '#8F8C83'}]}>
                        <Image
                            source={profileIamge}
                            style={SS.avataImage}
                        />
                    </View>
                    {
                        messages && messages.length>0 && 
                            <ShowMessageAlarm
                                messages = {messages}
                            />
                    }                    
                    <Text style={[GSS.mainSmallTextW, {marginTop:5, alignItems:'center'}]}>{nick}</Text>
                </View>            
            );
        });
    }

    const checkNetflixStatus = (userItem) => {
        //console.log(userItem);
        if (!_.isEqual(userItem, netflixUserItem)) {
            setNetflixUserItem(userItem);
        }
    };    

    const [modalID, setModalID] = useState();
    const checkNotifyError = (user_item) => {
        const id = openModal(
            <CheckNotifyError
              modalID={modalID}
              closeModal={closeModal}
              user_item= {user_item}
            /> ,
            0.4,    // heightPercentage, 필요한 경우 다른 값으로 설정
            0.9,   // widthPercentage
            'slide', // animation, 필요한 경우 다른 값으로 설정
            {},      // style, 필요한 경우 스타일 객체로 설정
            //true     // centered, 모달을 중앙에 배치하려면 true로 설정                
          );
          setModalID(id);
    }   

    const shareLink = async (userItem, OTT) => {
        //console.log(userItem.link_code);
        try {
            const shareUrl = `https://myshott.co.kr/B/${userItem.link_code}`;
            const message = `${OTT.name} 파티에 초대합니다. 여기를 눌러 확인하세요: ${shareUrl}`;
        
            if (Platform.OS === 'android') {
              // Android 플랫폼의 경우
              const result = await Share.share({
                message: shareUrl, // Android는 URL과 메시지를 함께 처리할 수 있음
              });
            } else if (Platform.OS === 'ios') {
              // iOS 플랫폼의 경우
              const result = await Share.share({
                message: message, // iOS는 메시지 내에 URL을 포함시키는 것이 좋음
              });
            } else {
              // 웹 플랫폼의 경우 (React Native Web을 사용하는 경우 별도의 처리가 필요할 수 있음)
              // 이 예시는 순수 웹 환경을 위한 코드이며, React Native Web에서는 navigator.share를 직접 사용할 수 없을 수 있음
              if (navigator.share) {
                navigator.share({
                  title: `${OTT.name} 파티에 초대합니다`,
                  url: shareUrl,
                }).then(() => {
                  //console.log('Sharing successful');
                }).catch((error) => {
                  //console.error('Sharing failed', error);
                });
              }
            }
        } catch (error) {
        console.error('Error during sharing', error);
        }       
    }

    const confirmCheckingError = (user_item) => {
        const item = getItemById(user_item.item_id);
        const OTT = GetOTTByName(item.item_name);       
        const _confirm = {
            afterOK : () => checkNotifyError(user_item),
            body : `[${OTT.name}] 파티에 문제가 있습니다. 지금 확인 하시겠습니까?`
        }
        confirmModal(_confirm);
    }

    const UserItemComponent = ({ user_item, checkStatus }) => {
        // const [userAccounts, setUserAccounts] = useState([]);
        // const [userAccountNumber, setUserAccountNumber] = useState(0);
        const item = getItemById(user_item.item_id);
        if( !item ) return null;
        //console.log(item);
        const OTT = GetOTTByName(item.item_name);
        const nicknames = user_item.nick_name ? user_item.nick_name.split('|||') : ['파티장'];
        const usedStartPos = (nicknames.length-1) * intervalSizeProfile + 20;
        //console.log(user_item.userNickNames);
        let user_nick_names = [];
        if( user_item.userNickNames )
        {
            user_nick_names = user_item.userNickNames?.split('|||');
            //console.log(user_nick_names);
        }
        const handleGoDisplayInfo = () => {
            // GoMatchingInfo 함수를 호출하는 코드를 이곳으로 옮깁니다.
            GoDisplayUserItem(user_item, item, userAccounts);
        };
        //console.log(user_item.item_name);

        //console.log('user_item.error_cnt: ', user_item.error_cnt);

        const { userAccounts, userAccountNumber } = useMemo(() => {
            let _userAccounts = [];
            let _userAccountNumber = 0;
    
            if (user_item.item_id === 1 && user_item.maximum_usage2 > 0) {
                if (user_item.account_email_id) {
                    _userAccounts.push({
                        account_id : user_item.account_email_id,
                        account_status : user_item.first_status,
                        account_email : user_item.first_email,
                        used_item_id : user_item.first_used_item_id,
                        message : UserItem.t_user_message.filter(obj => obj.is_host_message === 0
                             && obj.user_item_id === user_item.user_item_id
                             && obj.used_item_id === user_item.first_used_item_id )
                    });
                    if (user_item.first_status > 0) _userAccountNumber++;
                }
                if (user_item.account_email_id2) {
                    _userAccounts.push({
                        account_id : user_item.account_email_id2,
                        account_status : user_item.second_status,
                        account_email : user_item.second_email,
                        used_item_id : user_item.second_used_item_id,
                        message : UserItem.t_user_message.filter(obj => obj.is_host_message === 0
                            && obj.user_item_id === user_item.user_item_id
                            && obj.used_item_id === user_item.second_used_item_id )
                    });
                    if (user_item.second_status > 0) _userAccountNumber++;
                }
            }
    
            return { userAccounts: _userAccounts, userAccountNumber: _userAccountNumber };
        }, [user_item])        

        useEffect(() => {
            //console.log(userAccounts);
            const checkAccounts = userAccounts.filter(obj => obj.account_status===0);
            if(userAccounts.length !== 0 && checkAccounts.length === 0 ) {
                setNetflixUserItem(null);
            }
            if (user_item.item_id === 1 && checkAccounts.length > 0) {
                checkStatus({
                    user_item: user_item, 
                    userAccounts: checkAccounts,
                });
            }   
        }, [user_item, userAccounts]);
        
        return (
            <View style={[GSS.mainViewBox]}>
                <View style={{flexDirection:'row', justifyContent: 'space-between',}}>
                    <View style={{alignItems:'center', justifyContent:'center'}}>
                        <View style={{flexDirection:'row'}}>
                            <Text style={GSS.mainTitleText}>{OTT.name}</Text>
                            { user_item.error_cnt > 0 && (
                                <React.Fragment>
                                    <TouchableOpacity
                                        onPress={ () => checkNotifyError(user_item)}
                                    >
                                        <Image
                                            source={AlertImage}
                                            style={{height:25, width:25, marginLeft:5}}
                                        />
                                    </TouchableOpacity>
                                </React.Fragment>
                            )}
                        </View>
                    </View>
                    <View style={{flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                        { user_item.free_charge === 1 && (
                            <TouchableOpacity
                                onPress={() => shareLink(user_item, OTT)}
                                style={{marginRight:15}}
                            >
                                <Image
                                    source={LinkImage}
                                    style={{height:30, width:30}}
                                />               
                            </TouchableOpacity>                   
                        )}
                        <TouchableOpacity
                            onPress={handleGoDisplayInfo}                   
                        >
                            <View style={[GSS.labelContainer, {margin: 5, backgroundColor: '#f1f1f1'}]}>
                                <Text style={[GSS.labelText, {color: '#291B44'}]}>{'  공유 관리  '}</Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                </View>
                <CurvedBorder curveValue={-25} borderHeight={25}/>
                <View style={{ borderBottomLeftRadius:10, borderBottomRightRadius:10, justifyContent: 'center', backgroundColor:'#291B44', padding:20}}>
                    { user_item.item_id !== 1 && (
                        <React.Fragment>
                            <View style={{flexDirection:'row', justifyContent: 'space-between',}}>
                                <View style={{alignItems:'center', justifyContent:'center'}}>
                                    { user_item.free_charge === 1 ? (
                                        <Text style={GSS.mainSubjectTextW}></Text>
                                    ) : (
                                        <Text style={GSS.mainSubjectTextW}>{(item.monthly_price).toLocaleString()}원<Text style={{color:'#a09aac'}}> / 월</Text></Text>
                                    )}
                                </View>
                                <View style={{alignItems:'center', justifyContent:'center'}}>
                                    <Text style={{fontSize:25, color:'#F7F7F7'}}><Text style={{color:'#00e5e0'}}>{user_nick_names.length}</Text>/{user_item.maximum_usage}</Text>
                                </View>                     
                            </View>
                            <View style={{flexDirection:'row', justifyContent: 'space-between',position: 'relative'}}>
                                <View style={{justifyContent: 'center', alignItems: 'center'}}>
                                    <View style={[SS.avataContainer]}>
                                        <Image
                                            source={OTT.profileImage}
                                            style={SS.avataImage}
                                        />
                                    </View>
                                    <Text style={[GSS.mainSmallTextW, {marginTop:5, alignItems:'center'}]}>{nicknames[0]} (나)</Text>
                                </View>

                                <ShowProfile
                                    nicknames = {nicknames.slice(1)}
                                    profileIamge = {OTT.profileImage}
                                />

                                <ShowProfile
                                    nicknames = {user_nick_names}
                                    profileIamge = {OTT.profileImage}
                                    startpos={usedStartPos}
                                    used_item={true}
                                />                        
                            </View>
                        </React.Fragment>
                    )}
                    { (user_item.item_id === 1 && user_item.maximum_usage2 > 0) && (
                        <React.Fragment>
                            <View style={{flexDirection:'row', justifyContent: 'space-between', marginBottom:10}}>
                                <View style={{alignItems:'center', justifyContent:'center'}}>
                                    { user_item.free_charge === 1 ? (
                                        <Text style={GSS.mainSubjectTextW}></Text>
                                    ) : (
                                        <Text style={GSS.mainSubjectTextW}>{(item.monthly_price2).toLocaleString()}원<Text style={{color:'#a09aac'}}> / 월</Text></Text>
                                    )}                               
                                </View>
                                <View style={{alignItems:'center', justifyContent:'center'}}>
                                    <Text style={{fontSize:25, color:'#F7F7F7'}}><Text style={{color:'#00e5e0'}}>{userAccountNumber}</Text>/{user_item.maximum_usage2}</Text>
                                </View>                     
                            </View> 
                            <View style={{flexDirection:'row' }}>
                                {userAccounts.map((account, index) => (
                                        account.account_status === 0 ? (
                                        <Animatable.View key={`${account}-${index}`} animation="pulse" iterationCount="infinite" style={{ alignItems: 'center', justifyContent: 'center', marginRight:20 }}>
                                            <Animatable.View animation="customScale" iterationCount="infinite" direction="alternate">
                                                <View style={[SS.avataContainer,  {backgroundColor:'#F7F7F7'}]}>
                                                    <Image
                                                        source={OTT.profileImage2}
                                                        style={SS.avataImage}
                                                    />
                                                </View>
                                                <Text style={[GSS.mainSmallTextW, {marginTop:5, alignItems:'center'}]}>초대 필요</Text>
                                            </Animatable.View>
                                        </Animatable.View>  
                                    ) : (
                                        <View key={`${account}-${index}`} style={[SS.avataContainer,  {backgroundColor:'#F7F7F7', marginRight:20}]}>
                                            <Image
                                                source={OTT.profileImage}
                                                style={SS.avataImage}
                                            />
                                            { account.message && account.message.length>0 &&
                                                <ShowMessageAlarm
                                                    messages = {account.message}
                                                />
                                            }
                                        </View>  
                                    )
                                ))}
                            </View>
                        </React.Fragment>
                        )}
                </View>
            </View>                 
        );
    };

    const ShowUserItem = ({checkStatus}) => {
        // UserItem.t_user_item의 유효성 확인
        //console.log('HomeOfLogin(ShowUserItem) : ', UserItem.t_user_item.length);
        
        //console.log(`공유 아이템수 : ${UserItem.t_user_item.length}`);
        if(!UserItem.t_user_item || UserItem.t_user_item.length === 0) {
            return null;
        }
        //console.log(UserItem.t_user_item);

        return UserItem.t_user_item.map((user_item, index) => {
            return <UserItemComponent key={index} user_item={user_item} checkStatus={checkStatus} />;
        });   
    }

    // 사용자 지정 scale 애니메이션을 만듭니다.
    Animatable.initializeRegistryWithDefinitions({
        customScale: {
            0: {
                scale: 1,
            },
            0.5: {
                scale: 1.2,
            },
            1: {
                scale: 1,
            },
        },
    });    

    const ShowWaitingList = () => {
        return UserItem.t_waiting_list.map((waitingItem, index) => {
            const item = getItemById(waitingItem.item_id);
            const OTT = GetOTTByName(item.item_name);

            const handleGoMatchingInfo = () => {
                // GoMatchingInfo 함수를 호출하는 코드를 이곳으로 옮깁니다.
                GoMatchingInfo(waitingItem, item);
            };

            if( Platform.OS === targetOS ) {
                return (
                    <View key={index} style={[GSS.mainViewBox, {flexDirection:'row', alignItems:'center', justifyContent:'center'}]}>
                        <View style={{flex:1, flexDirection:'row', justifyContent:'space-between', alignItems:'center', marginLeft:10}}>
                            <Animatable.View animation="pulse" iterationCount="infinite" style={{ alignItems: 'center', justifyContent: 'center' }}>
                                <Animatable.View animation="customScale" iterationCount="infinite" direction="alternate">
                                    <View style={{ padding: 5, }}>
                                        <Text style={GSS.mainTitleText}>{item.display_name} 매칭 중...</Text>                                
                                    </View>
                                </Animatable.View>
                            </Animatable.View>                    
                            <TouchableOpacity
                               onPress={handleGoMatchingInfo}
                             >
                                <Image
                                    source={nextImage}
                                    style={{width:40, height:40}}
                                />
                            </TouchableOpacity>                                       
                        </View>
                    </View>
                );
            } else {
                return (
                    <View key={index} style={[GSS.mainViewBox, {flexDirection:'row', alignItems:'center', justifyContent:'center'}]}>
                        <Animatable.View animation="pulse" iterationCount="infinite" style={{ alignItems: 'center', justifyContent: 'center' }}>
                            <Animatable.View animation="customScale" iterationCount="infinite" direction="alternate">
                                <View style={{ borderWidth:1, borderColor:'#e8e8e8',  padding: 5, width:50, height:50, borderRadius:25}}>
                                    <Image
                                        source={OTT.logoImage}
                                        style={SS.ottImage}
                                    />
                                </View>
                            </Animatable.View>
                        </Animatable.View>                    
                        <View style={{flex:1, flexDirection:'row', justifyContent:'space-between', alignItems:'center', marginLeft:10}}>
                            <Text style={GSS.mainTitleText}>{item.display_name} 매칭 중...</Text>
                            <TouchableOpacity
                               onPress={handleGoMatchingInfo}
                             >
                                <Image
                                    source={nextImage}
                                    style={{width:40, height:40}}
                                />
                            </TouchableOpacity>                                       
                        </View>
                    </View>
                );
            }
        });   
    }

    const ShowMatchedList = () => {
        //console.log(UserItem.t_used_item);
        const matchedItems = UserItem.t_used_item.filter(obj => obj.using_status === 0);
        //console.log(matchedItems);
        return matchedItems.map((matchedItem, index) => {
            const item = getItemById(matchedItem.item_id);
            const OTT = GetOTTByName(item.item_name);
            const handleGoMatchedInfo = () => {
                // GoMatchingInfo 함수를 호출하는 코드를 이곳으로 옮깁니다.
                GoMatchedProc(matchedItem, item);
            };
            if(Platform.OS === targetOS) {
                return (
                    <View key={index} style={[GSS.mainViewBox, {flexDirection:'row', alignItems:'center', justifyContent:'center'}]}>
                        <View style={{flex:1, flexDirection:'row', justifyContent:'space-between', alignItems:'center', marginLeft:10}}>
                            <Animatable.View animation="pulse" iterationCount="infinite" style={{ alignItems: 'center', justifyContent: 'center' }}>
                                <Animatable.View animation="customScale" iterationCount="infinite" direction="alternate">
                                    <View style={{ padding: 5, }}>
                                        <Text style={GSS.mainTitleText}>{item.display_name}매치 완료</Text>                                
                                    </View>
                                </Animatable.View>
                            </Animatable.View>                    
                            <TouchableOpacity
                               onPress={handleGoMatchedInfo}
                             >
                                <Image
                                    source={nextImage}
                                    style={{width:40, height:40}}
                                />
                            </TouchableOpacity>                                       
                        </View>
                    </View>
                );
            } else {
                return (
                    <View key={index} style={[GSS.mainViewBox, {flexDirection:'row', alignItems:'center', justifyContent:'center'}]}>
                        <Animatable.View animation="pulse" iterationCount="infinite" style={{ alignItems: 'center', justifyContent: 'center' }}>
                            <Animatable.View animation="customScale" iterationCount="infinite" direction="alternate">
                                <View style={{ borderWidth:1, borderColor:'#00cdd0',  padding: 5, width:50, height:50, borderRadius:25}}>
                                    <Image
                                        source={OTT.logoImage}
                                        style={SS.ottImage}
                                    />
                                </View>
                            </Animatable.View>
                        </Animatable.View>                    
                        <View style={{flex:1, flexDirection:'row', justifyContent:'space-between', alignItems:'center', marginLeft:10}}>
                            <Text style={GSS.mainTitleText}>{item.display_name} 매치 완료</Text>
                            <TouchableOpacity
                               onPress={handleGoMatchedInfo}
                             >
                                <Image
                                    source={nextImage}
                                    style={{width:40, height:40}}
                                />
                            </TouchableOpacity>                                       
                        </View>
                    </View>
                );
            }
        });   
    }   

    const checkEmailContent = (emailAccount) => {
        navigation.navigate('CheckEmailContent', { emailAccount : emailAccount });
    }   
    
    const ShowMatchedNexflix = () => {
        if(emailAccountInfo === null && !emailAccountInfo.emailAccount ) return null;
        const matchedItem = emailAccountInfo.used_item;
        const item = getItemById(matchedItem.item_id);
        const OTT = GetOTTByName(item.item_name);
        const handleGoMatchedInfo = (emailAccount) => {
            // GoMatchingInfo 함수를 호출하는 코드를 이곳으로 옮깁니다.
            //checkEmailContent(emailAccount);
            const _message = {
                afterOK : () => checkEmailContent(emailAccount),
                isCode : true,
                body: () => (
                    <View>
                        <Image
                            source={infoImage}
                            style={{height:50, width:50, marginBottom:10}}
                        />
                        <Text style={GSS.mainInfoTextB }>넷플릭스 초대메일에 대한 수락을 하여 자동생성된 비밀번호를 설정합니다.</Text>
                        <Text style={[GSS.textEmphasis, {fontSize:16, marginTop:5, marginBottom:5}]}>진행 완료 후 오른쪽 하단의 완료 버튼을 클릭하여 저장해 주세요</Text>
                    </View>
                )
            } 
            showModalMessage(_message, 0.45);           
        };       
        return (
            <View style={[GSS.mainViewBox, {flexDirection:'row', alignItems:'center', justifyContent:'center'}]}>
                <Animatable.View animation="pulse" iterationCount="infinite" style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <Animatable.View animation="customScale" iterationCount="infinite" direction="alternate">
                        <View style={{ borderWidth:1, borderColor:'#00cdd0',  padding: 5, width:50, height:50, borderRadius:25}}>
                            <Image
                                source={OTT.logoImage}
                                style={SS.ottImage}
                            />
                        </View>
                    </Animatable.View>
                </Animatable.View>                    
                <View style={{flex:1, flexDirection:'row', justifyContent:'space-between', alignItems:'center', marginLeft:10}}>
                    <Text style={GSS.mainTitleText}> 추가회원 초대를 수락 진행</Text>
                    <TouchableOpacity
                       onPress={() => handleGoMatchedInfo(emailAccountInfo.email_account)}
                     >
                        <Image
                            source={nextImage}
                            style={{width:40, height:40}}
                        />
                    </TouchableOpacity>                                       
                </View>
            </View>
        );  
    }    

    // const ShowMatchedNetflix = React.memo(({ userItem }) => {
    //     if( !userItem) return null;
    //     const item = getItemById(userItem.user_item.item_id);
    //     const OTT = GetOTTByName(item.item_name);
    //     const handleGoMatchedInfo = () => {
    //         // GoMatchingInfo 함수를 호출하는 코드를 이곳으로 옮깁니다.
    //         GoNetflixProc(userItem.user_item, item, userItem.userAccounts);
    //     };

    //     return (
    //         <View style={[GSS.mainViewBox, {flexDirection:'row', alignItems:'center', justifyContent:'center'}]}>
    //             <Animatable.View animation="pulse" iterationCount="infinite" style={{ alignItems: 'center', justifyContent: 'center' }}>
    //                 <Animatable.View animation="customScale" iterationCount="infinite" direction="alternate">
    //                     <View style={{ borderWidth:1, borderColor:'#00cdd0',  padding: 5, width:50, height:50, borderRadius:25}}>
    //                         <Image
    //                             source={OTT.logoImage}
    //                             style={SS.ottImage}
    //                         />
    //                     </View>
    //                 </Animatable.View>
    //             </Animatable.View>                    
    //             <View style={{flex:1, flexDirection:'row', justifyContent:'space-between', alignItems:'center', marginLeft:10}}>
    //                 <Text style={GSS.mainTitleText}> 추가회원 초대하기</Text>
    //                 <TouchableOpacity
    //                    onPress={handleGoMatchedInfo}
    //                  >
    //                     <Image
    //                         source={nextImage}
    //                         style={{width:40, height:40}}
    //                     />
    //                 </TouchableOpacity>                                       
    //             </View>
    //         </View>
    //     );
    // });

    const MessageViewer = ({_messages}) => {
        const [messages, setMessages] = useState(_messages); // 초기 메시지 배열
        const [currentIndex, setCurrentIndex] = useState(0); // 현재 메시지 인덱스
        const [allMessagesRead, setAllMessagesRead] = useState(false);
      
        const goToPrevMessage = () => {
          setCurrentIndex(currentIndex - 1);
        };
      
        const goToNextMessage = () => {
          setCurrentIndex(currentIndex + 1);
        };

        // 마지막 메시지에 도달했는지 확인
        useEffect(() => {
            if (currentIndex === messages.length - 1) {
                setAllMessagesRead(true);
            }
        }, [currentIndex]);

        const confirmMessage = async () => {
            try {
                const message_ids = messages.map(msg => msg.user_message_id);
                //console.log(message_ids);
                await UpdateUserMessageToDB(message_ids);
                await ReadUserItem(ENV.user_id);
                closeModal(msgViewerModalID);
            } catch (error) {
                console.error("MessageViewer error:", error);
                // 필요한 추가적인 에러 처리 로직
            }
        };
      
        return (
            <View style={{ flex: 1, padding: 5, margin:5}}>
                <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                    <View>
                        <Text style={GSS.messageTitleText}>'<Text style={GSS.textEmphasis}>{truncateString(messages[currentIndex]?.from_nick_name, 10)}</Text>'님의 쪽지</Text>
                    </View>
                    <View style={{ flexDirection: 'row', padding: 10 }}>
                        <TouchableOpacity
                            disabled={currentIndex === 0}
                            onPress={goToPrevMessage}
                        >
                            <Image
                                source={currentIndex === 0 ? prevImage : prevBImage}
                                style={{height:30, width:30}}
                            />                           
                        </TouchableOpacity>
                        <TouchableOpacity
                            disabled={currentIndex === messages.length - 1}
                            onPress={goToNextMessage}
                        >
                            <Image
                                source={currentIndex === messages.length - 1 ? nextImage : nextBImage}
                                style={{height:30, width:30}}
                            />
                        </TouchableOpacity>
                    </View>
                </View>
                <ScrollView style = {{flex:5}}>
                    <Text style={GSS.messageText}>{messages[currentIndex]?.user_message}</Text>
                </ScrollView>
                <View style={{alignItems:'center', marginTop:15}}>
                    <ProgressButton
                        title ='확인'
                        style = {allMessagesRead ? {backgroundColor:'#1e1f31'} : {backgroundColor:'#cbcbe3'}}
                        textStyle = {{color:'#FFFFFF'}}
                        disabled={!allMessagesRead}
                        onPress={confirmMessage}                
                    />
                </View>
            </View>
        );
    };

    const ShowMessageAlarm = ({messages}) => {
        //console.log(messages);
        const handleGoShowMessage = (messages) => {
            //console.log('messages :', messages);
            msgViewerModalID = openModal(
                <MessageViewer
                    _messages = {messages}
                /> ,
                0.4,    // heightPercentage, 필요한 경우 다른 값으로 설정
                0.85,   // widthPercentage
                'slide', // animation, 필요한 경우 다른 값으로 설정
                {},      // style, 필요한 경우 스타일 객체로 설정
                true     // centered, 모달을 중앙에 배치하려면 true로 설정                
            );        

        };

        return (
            <Animatable.View animation="pulse" iterationCount="infinite" style={SS.message}>
                <Animatable.View animation="customScale" iterationCount="infinite" direction="alternate">
                    <View>
                        <TouchableOpacity
                                    onPress={() => handleGoShowMessage(messages)}
                        >                  
                            <Image
                                source = {Message}
                                style = {{height: 25,width: 25}}
                            />
                        </TouchableOpacity>
                    </View>
                </Animatable.View>
            </Animatable.View>
        );
    }

    const ShowUsedItem = () => {
        //console.log(UserItem.t_used_item);
        if(!UserItem.t_used_item || UserItem.t_used_item.length === 0 ||
            !UserItem.t_used_item.some(obj => obj.using_status === 1)  ) {
            return null;
        }
        const matchedItems = UserItem.t_used_item.filter(obj => obj.using_status === 1);
        return matchedItems.map((used_item, index) => {
            const item = getItemById(used_item.item_id);
            if( !item ) return null;
            //console.log(item);
            const OTT = GetOTTByName(item.item_name);
            const date = new Date(used_item.start_use);

            const handleGoMatchedInfo = () => {
                // GoMatchingInfo 함수를 호출하는 코드를 이곳으로 옮깁니다.
                GoDisplayUsedItem(used_item, item);
            };

            //console.log(UserItem.t_user_item);
            const messages = UserItem.t_user_message.filter(obj => obj.is_host_message === 1 && obj.used_item_id === used_item.used_item_id);
            //console.log(messages);

            let monthly_price = item.monthly_price;
            let profileImage = OTT.profileImage;
            if(used_item.using_type === 1) {
                monthly_price = item.monthly_price2;
                profileImage = OTT.profileImage;
            }
            return (
            <View key={index} style={GSS.mainViewBox}>
                <View style={{flexDirection:'row', justifyContent: 'space-between', padding:10, marginBottom:-15}}>
                    <Text style={GSS.mainBigText}>{OTT.name}</Text>
                    { used_item.free_charge !== 1 && (
                        <View>
                            <Text style={GSS.mainBigText}>{(monthly_price).toLocaleString()}원</Text>

                            <Text style={{color:'#a09aac'}}>/월</Text>
                        </View>                  
                    )}                   
                </View>
                <CurvedBorder curveValue={-25} borderHeight={25} style={{borderWidth:0}}/>
                <View style={{borderBottomLeftRadius:10, borderBottomRightRadius:10, justifyContent: 'center', backgroundColor:'#291B44', padding:20}}>
                    <View style={{flexDirection:'row', justifyContent: 'space-between',position: 'relative'}}>
                        <View style={{justifyContent: 'center', alignItems: 'center'}}>
                            <View style={[SS.avataContainer]}>
                                <Image
                                    source={profileImage}
                                    style={SS.avataImage}
                                />
                            </View>
                            {
                                messages && messages.length>0 && 
                                    <ShowMessageAlarm
                                        messages = {messages}
                                    />
                            }
                            <Text style={[GSS.mainSmallTextW, {marginTop:5, alignItems:'center'}]}>{used_item.nick_name}</Text>
                        </View>
                        <View style={{alignItems:'center', justifyContent:'center'}}>
                            <Text style={{fontSize:16, color:'#F7F7F7'}}>자동결제 : 매달 {date.getDate()}일</Text>
                        </View>                           
                        <View style={{alignItems:'center', justifyContent:'center'}}>
                            <TouchableOpacity
                                onPress={handleGoMatchedInfo}
                            >
                                <Image
                                    source={nextImageW}
                                    style={{width:40, height:40}}
                                />
                            </TouchableOpacity>     
                        </View>                        
                    </View>
                </View>
            </View>                 
            );
        });   
    }

    const GoMatchingInfo = (waitingItem, item) => {
        navigation.navigate('MatchWaiting', { waitingItem, item });
    }    

    const GoMatchedProc = (matchedItem, item) => {
        navigation.navigate('MatchedProc1', { matchedItem, item });
    } 
    
    const GoDisplayUsedItem = (used_item, item) => {
        navigation.navigate('DisplayUsedItem', { used_item, item });
    }      

    const GoDisplayUserItem = (user_item, item, userAccounts) => {
        navigation.navigate('DisplayUserItem', { user_item, item, userAccounts });
    }    

    const GoShareHome = () => {
        navigation.navigate('Share', {
            // 다른 탭으로 전달할 필요가 있는 데이터가 있다면 여기에 전달
            // 예: { someData: 'your data' }
          });
    }   

    const GoNetflixProc = (user_item, item, userAccounts) => {
        // if( Platform.OS === 'web') {
        //     showMessage('안드로이드 또는 iOS 모바일 앱을 이용해 진행해 주세요');
        //     //navigation.navigate('NetflixMatchedProc_web', { user_item, item, userAccounts });
        // } else {
        //     navigation.navigate('NetflixMatchedProc', { user_item, item, userAccounts });
        // }
        //console.log(userAccounts);
        navigation.navigate('NetflixMatchedProc', { user_item, item, userAccounts });
    }

    const RefreshUserItem = async () => {
        const ret = await ReadUserItem(ENV.user_id);
    }  
    
    useEffect(() => {
        (async () => {
            try {
                const ret = await CheckCertiIDOfDB(ENV.user_id);
                if( ret.success) {
                  if(ret.data.checked_certification === 1) {
                    setInviteCode(ret.data.invite_code);
                  }
                }
            } catch(e) {
                console.log(e);
            }
        })(); 
      }, []);    
    
    const shareMessage = async () => {
        try {
            let invite_code;
            if( inviteCode ) {
                invite_code = inviteCode;
            } else {
                invite_code = 0;
            }
            const result = await Share.share({
            //message: '마이샷을 지인에게 초대해 주세요',
            message: `https://myshott.co.kr/406/${inviteCode}`,
            // URL을 추가하려면 아래 주석을 해제하세요.
            url: `https://myshott.co.kr/406/${inviteCode}`,
            //url: `http://localhost:19006/406/${inviteCode}`,
            title: '마이샷 공유하기'
          });
      
          if (result.action === Share.sharedAction) {
            if (result.activityType) {
              // 공유된 액티비티 타입이 있는 경우 처리
              //console.log('ActivityType:', result.activityType);
            } else {
              // 공유가 성공적으로 완료된 경우 처리
            }
          } else if (result.action === Share.dismissedAction) {
            // 공유가 취소된 경우 처리
          }
        } catch (error) {
          console.error('Error during sharing', error);
        }
    };    

    return (
        <View style={GSS.mainContainer}>
            <View style={[GSS.emptyViewBox, {marginTop:15, marginBottom:15, flexDirection:'row', justifyContent:'space-between'}]}>
                <Text style={GSS.mainTitleText}>공유중 ({shareCount}){waitingCount ?  `, 대기중(${waitingCount})` : ''}</Text>
                <TouchableOpacity
                    onPress={RefreshUserItem}
                >
                    <Image
                        source={RefreshImage}
                        style={{height:25, width:25}}
                    />
                </TouchableOpacity>
            </View>
            <LinearGradient
                // 그라데이션 색상을 지정합니다. 시작 색상에서 종료 색상으로 변경됩니다.
                //colors={['#ecc7a1', '#f1d8bd', '#f7e7d8']}
                colors={['#C9AA00', '#EDC800', '#FFD700']}
                // 그라데이션의 시작점과 끝점을 정의 (x축 방향으로 그라데이션)
                start={{ x: 0, y: 0 }}
                end={{ x: 1, y: 0 }}  // x축 방향으로 이동하면서 색상 변경            
                style={styles.container} // 변경된 스타일 참조
            >
                <Text style={styles.text}>현재 절약한 금액이 <Text style={{color:'#291B44'}}>{savingCost.toLocaleString()}원</Text>입니다</Text>
                {/* <Text style={styles.text}>지금까지 <Text style={{color:'#291B44'}}>{savingCost.toLocaleString()}원</Text> 절약하였습니다</Text> */}
                <View style={{}}>
                    <TouchableOpacity
                        style={{flexDirection:'row', alignItems:'center'}}
                        onPress={shareMessage}
                    >
                        <Text style={[GSS.mainDefaultTextB, {margin:10}]}>마아샷을 알려주시고 다양한 혜택을 받으세요</Text>
                        <Image
                            source={ShareLinkImage}
                            style={{height:25, width:25}}
                        />
                </TouchableOpacity>                   
                </View>
             </LinearGradient>

            { emailAccountInfo && (
                <ShowMatchedNexflix/>
            )}

            <ShowMatchedList/>

            <ShowWaitingList/>

            <ShowUsedItem/>
           
            <ShowUserItem
                checkStatus={checkNetflixStatus}
            />

            <View style={SS.addContainer}>
                <TouchableOpacity
                    onPress={GoShareHome}
                >
                    <Image
                        source={addImage}
                        style={SS.addImage}
                    />               
               </TouchableOpacity>                
                <Text style={GSS.mainInfoTextB}>공유 추가</Text>
            </View>
        </View>
    );
});

const SS = StyleSheet.create({
    avataContainer: {
        height: 50,
        width: 50,
        borderRadius: 25,
        backgroundColor: '#8F8C83',
        padding: 5,
    },
    avataImage: {
        height: 40,
        width: 40,
        borderRadius: 20,
        backgroundColor: '#F7F7F7',
        alignItems: 'center'
    },
    addContainer: {
        padding: 35,
        margin: 5,
        borderRadius: 10, // 외곽선의 모서리 반경
        justifyContent: 'center',
        alignItems:'center',
        backgroundColor: '#FFFFFF',
        borderWidth:1, 
        borderColor:'#6F6C63', 
        borderStyle: 'dotted',     
    },
    addImage: {
        width: 60,
        height: 60,
        marginBottom: 15
    },
    ottImage: {
        height: 40,
        width: 40,
        // borderRadius: 25,
        // borderWidth:1,
        // borderColor: '#e8e8e8',
        alignItems: 'center'
    },    
    message: {
        position: 'absolute',
        top: -10,
        right: -10,
    }    
});

const styles = StyleSheet.create({
    container: {
      flex: 1,
      //alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 15,
      padding: 5,
      margin: 5,      
    },
    // background 스타일 정의는 이제 불필요하므로 제거할 수 있습니다.
    text: {
      fontSize: 20,
      fontWeight: 'bold',
      //textAlign: 'center',
      margin: 10,
      color: '#ffffff',
    },
});

export default HomeOfLogin;
