import React, {useState, useEffect, useCallback } from 'react';
import { 
  Text, View, Image, TextInput, Platform
} from 'react-native';
import { useAuth, useEnv, OTTs} from '../AppContext';
import { useItemContext } from '../ItemContext';
import infoImage from '../../assets/info_right.png';
import GSS from '../GlobalStyleSheet2';
import TopHeader from '../TopHeader';
import { ProgressNext } from '../ProgressButton';
import { targetOS } from '../Global';

function Share11 ({navigation, route}) {
  const { isLoggedIn  } = useAuth();
  const { ENV } = useEnv();
  const {Item, setItem} = useItemContext();
  const [checkCompleted, setCheckCompleted] = useState(false);
  let cardName = route.params?.cardName;
  let cardNum = route.params?.cardNum;    
  const [name, setName] = useState();
  const [email, setEmail] = useState();

  function NextStep()
  {
    const newData = { ...Item };
    newData.user_accounts = email;
    setItem(newData);
    navigation.navigate('Share2', {cardName, cardNum});
  }

  const NextButton = () => {
    return (
      <ProgressNext
        title={'다음'}
        onPress={ checkCompleted ? NextStep : null}
        disabled={checkCompleted ? false : true}
      />
    );
  }  

  const handleChangeEmail = (input) => {
    // 금지된 문자들
    const restrictedChars = [' ', '!', '#', '$', '%', '^', '&', '*', '(', ')', '+', '=', '{', '}', '[', ']', '|', '\\', ':', ';', '\'', '"', '<', '>', ',', '?', '/'];
    const cleanInput = input.split('').filter(char => !restrictedChars.includes(char) && !/[ㄱ-ㅎ가-힣]/.test(char)).join('');

    // 이메일 형식 검사를 위한 정규 표현식
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // 상태 업데이트
    setEmail(cleanInput);

    // cleanInput이 이메일 형식일 경우 특정 설정 적용
    if (emailRegex.test(cleanInput)) {
      setCheckCompleted(true);
    } else {
      setCheckCompleted(false);
    }
  };  

  return (
    <View style={GSS.mainContainer}>
      <TopHeader 
        titleImage={Platform.OS != targetOS && OTTs[Item.ott_id].iconImage}
        navigation={navigation}
      >
        {Item.display_name} 추가회원 계정 설정
      </TopHeader>              
      <View style={[GSS.mainViewBox, {flexDirection : 'row', justifyContent:'center', alignItems:'center'}]}>
        <View><Image source={infoImage} style={{ width: 25, height: 25, marginLeft:5 }}/></View>
        <View style={{padding: 5}}>
          <Text style={GSS.mainDefaultText}>• {Item.display_name}에서 <Text style={GSS.textEmphasis}>사용할 계정</Text>을 설정합니다.</Text>
          <Text style={GSS.mainDefaultText}>• 설정하신 이메일로 파티장님으로 부터<Text style={GSS.textEmphasisAlert}> 초대메일</Text>을 받습니다.</Text>
          <Text style={GSS.mainDefaultText}>• 설정하신 이메일이 넷플릭스에서 사용할 계정이 됩니다.</Text>
        </View>
      </View>
      <View style={[GSS.mainViewBox , {flex:1}]}>
        {/* <View style={{marginBottom:15, marginLeft:20}}>
            <Text style={GSS.mainSmallText}>ID는 영문자를 입력해세요(한글입력불가)</Text>
        </View>                
        <View style={GSS.textInputContainer}>
            <Text style={[GSS.textInputTile, {width: 40}]}>ID</Text>
            <TextInput
                style={GSS.textInputText}
                placeholder={`${Item.display_name} 아이디를 입력`}
                value={name}
                maxLength={60}
                // onChangeText={handleChangeID}
            />
        </View> */}
        <View style={GSS.textInputContainer}>
          <Text style={[GSS.textInputTile, {width: 50}]}>이메일</Text>
          <TextInput
              style={GSS.textInputText}
              placeholder={`이메일 주소를 입력`}
              value={email}
              maxLength={60}
              onChangeText={handleChangeEmail}
              //secureTextEntry // 비밀번호 필드로 설정합니다.
          />
        </View>                   
      </View>
      <View style={[GSS.mainViewBox, {margin: 5, alignItems:'center', justifyContent: 'center'}]}>
        <NextButton/>
      </View> 
    </View> 
  );
};

export default Share11;