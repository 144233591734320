import React, { useEffect } from 'react';
import { SafeAreaView, Platform, View, Text, ActivityIndicator} from "react-native";
import { WebView } from 'react-native-webview';
import Axios from 'axios';
import { showToast, routerServer, LoadFromSystem, SaveToSystem } from '../Global';
import { useEnv, useSystemInfo } from '../AppContext';
import GSS from '../GlobalStyleSheet2';
import { useModal } from '../ModalContext';
import { useFocusEffect } from '@react-navigation/native';
import { useLoginProc } from '../LoginProc2';
import { useItemContext } from '../ItemContext';
import { useInitProc } from '../InitProc';

const _ROUTER_SERVER = "https://myshott.co.kr";
//const _ROUTER_SERVER = routerServer;

export default function RegisterCardOfPayple({navigation, route}) {
    if(Platform.OS === 'web') {
        const {ENV} = useEnv();
        const { showModalMessage } = useModal();
        const targetAfterRegister = route.params?.targetAfterRegister;
        const saveData = route.params?.saveData;
        let card_info = route.params?.card_info;
        const { loginProc } = useLoginProc();
        const { setSystemInfo }= useSystemInfo();
        const { setItem} = useItemContext();
        const { initProc} = useInitProc();
    
        // useFocusEffect (
        //     React.useCallback(() => {
        //       // 포커스될 때 실행할 코드
        //       //console.log('스크린이 포커싱되었습니다.');
    
        //         //console.log(user_id, last_login_type, ENV.target_after_login);
        //         (async () => {
        //             try {
    
        //             } catch (e) {
        //                 console.error(e);
        //             }
        //         })();          
       
        //         return () => {
        //             // 스크린이 포커스를 잃을 때 실행할 정리(clean-up) 코드
        //             //console.log('스크린이 포커스를 잃었습니다.');
        //             if(card_info) {
        //                 card_info = null;
        //             }
        //         };
        //     }, [card_info, ENV])
        //   );
         
        useEffect(() => {
            // async 함수를 정의하고 즉시 호출합니다.
            (async () => {
                try {
                    let user_id;
                    let target_after_login;
                    if(card_info) {
                        const newENV = await initProc();
                        await loginProc(newENV);
                        user_id = newENV.user_id;
                        target_after_login = newENV.target_after_login;         
                    } else {
                        user_id = ENV.user_id;
                        target_after_login = ENV.target_after_login;
                    }
                    if(targetAfterRegister) {
                        target_after_login = targetAfterRegister;
                    }
    
                    if(card_info) {
                        //const tmpDataString = localStorage.getItem('tmp_data');
                        // const loadData = JSON.parse(tmpDataString);
                        // if( loadData ) {
                        //     //console.log(loadData);
                        //     setItem(loadData.Item);
                        //     setSystemInfo(loadData.systemInfo);
                        // }
    
                        //console.log(card_info);
                        // URL 디코딩
                        const decodedJson = decodeURIComponent(card_info);
                        const ret = JSON.parse(decodedJson);
                        //console.log('pay_rst : ', ret);
                        let payUpdated = false;
                        const _message = {
                            title : '결제정보 등록',
                            body : '',
                        };            
                        if( ret.payer_id && ret.payer_id !== '' ) {
                            payUpdated = true;
                            _message.body = 
            `[${ret.pay_cardname}(${ret.pay_cardnum})]가 지정되었습니다. 
            체크/신용카드의 유효성을 확인 하는 과정 중에 소액(100원)이
            결제 되지만 바로 취소됩니다.`;
                            showModalMessage(_message); 
                        } else {
                            _message.body = ret.pay_msg;
                            showModalMessage(_message); 
                        }
                        if( target_after_login) {
                            if( target_after_login === 'Share2' && payUpdated) {
                                const cardName = ret.pay_cardname;
                                const cardNum = ret.pay_cardnum;
                                navigation.navigate('Tabs', {
                                    screen: 'Share', // Tabs 내부의 Home 탭으로 이동
                                    params: {
                                      screen: target_after_login, // Home 탭 내부의 HomeInfo 스크린으로 이동
                                      params: { cardName, cardNum},
                                    },
                                });
                            } else {
                                //navigation.navigate(target_after_login, {used_item, item});
                                navigation.navigate('Tabs', {
                                    screen: 'Share', // Tabs 내부의 Home 탭으로 이동
                                    params: {
                                      screen: target_after_login, // Home 탭 내부의 HomeInfo 스크린으로 이동
                                    },
                                });                               
                            }
                        } else {
                            //navigation.navigate('AdminPay',  {payUpdated});
                            navigation.reset({
                                index: 0, // 첫 번째 인덱스에서 스크린을 활성화
                                routes: [
                                  {
                                    name: 'Tabs', // 최상위 Tabs 스크린을 지정
                                    state: {
                                      routes: [
                                        {
                                          name: 'MyInfo', // Tabs 내의 MyInfo 탭 스크린
                                          state: {
                                            routes: [
                                              {
                                                name: 'AdminPay', // MyInfo 탭 안의 AdminPay 스크린
                                                params: {
                                                  payUpdated: true, // 전달할 매개변수
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                            });                          
                        } 
                    } else {
                        if(user_id) {
                            const newENV = { ... ENV};
                            newENV.target_after_login = targetAfterRegister;
                            await SaveToSystem(newENV);
                            if( saveData ) {
                                const sysDataString = JSON.stringify(saveData);
                                localStorage.setItem('tmp_data', sysDataString);
                            }
    
                            const url = `${routerServer}/payple/register_card/WEB${user_id}`;
                            //console.log(url);
                            // Linking.openURL(url)
                            // .catch(err => console.error('An error occurred', err)); 
                            window.location.href = url;                  
                        } else {
                            const _message = {
                            body : '로그인을 다시 시도하고 진행해주세요'
                            }
                            showModalMessage(_message);   
                            navigation.reset({
                                index: 0,
                                routes: [
                                    { 
                                        name: 'MyInfo',
                                    },
                                ],
                            });             
                        }                       
                    }
                } catch (e) {
                    // 오류를 적절하게 핸들링합니다. (예: 로깅, 사용자에게 메시지 표시 등)
                    console.error(e);
                }
            })();
        }, [card_info]);  
    
        return (
            <View style={GSS.mainContainer}>
                <View style={{alignItems: 'center', justifyContent:'center'}}>
                    <Text style={GSS.mainDefaultText}>...</Text>
                    <View style={GSS.activityIndicatorContainer}>
                        <ActivityIndicator size='large' color="#0000ff" />
                    </View>                    
                </View>       
          </View>
        ); 
    } else {
        const {ENV} = useEnv();
        const { showModalMessage } = useModal();
        const targetAfterRegister = route.params?.targetAfterRegister;
        const used_item = route.params?.used_item;
        const item = route.params?.item;
    
        const onMessage = (event) => {
            const uri = event.nativeEvent.url;
            //console.log(uri);
            if(uri.startsWith(_ROUTER_SERVER) || uri.startsWith(routerServer)) {
                const ret = JSON.parse(event.nativeEvent.data);
                //console.log('pay_rst : ', ret);
                let payUpdated = false;
                const _message = {
                    title : '결제정보 등록',
                    body : '',
                };            
                if( ret.payer_id && ret.payer_id !== '' ) {
                    payUpdated = true;
                    _message.body = 
    `[${ret.pay_cardname}(${ret.pay_cardnum})]가 지정되었습니다. 
     체크/신용카드의 유효성을 확인 하는 과정 중에 소액(100원)이
     결제 되지만 바로 취소됩니다.`;
                    showModalMessage(_message); 
                } else {
                    _message.body = ret.pay_msg;
                    showModalMessage(_message); 
                }
                if( targetAfterRegister) {
                    if( targetAfterRegister === 'Share2' && payUpdated) {
                        const cardName = ret.pay_cardname;
                        const cardNum = ret.pay_cardnum;
                        navigation.navigate(targetAfterRegister, {cardName, cardNum, used_item, item});
                    } else {
                        navigation.navigate(targetAfterRegister, {used_item, item});
                    }
                } else {
                    navigation.navigate('AdminPay',  {payUpdated});
                }
            }        
        };
    
        return (
            <SafeAreaView style={GSS.droidSafeArea}>
                <WebView 
                    originWhitelist={['*']}
                    scalesPageToFit={false}
                    source={{ uri: `${routerServer}/payple/register_card/${ENV.user_id}`, webSecurityEnabled: false }}
                    javaScriptEnabled={true}
                    onMessage={onMessage}
                    //source={{ html: htmlContent }}
                />
            </SafeAreaView>
        ); 
    }
}
