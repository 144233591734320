import { Alert } from 'react-native';
import { RegisterPushNotiToDB } from './api';
import * as Notifications from 'expo-notifications';
import Constants from 'expo-constants';

export async function RegisterForPushNotifications(user_id) {

    let result = false;
    try {
        let token, token2;
        const { status: existingStatus } = await Notifications.getPermissionsAsync();
        //console.log(existingStatus);
        let finalStatus = existingStatus;
        if (existingStatus !== 'granted') {
            const { status } = await Notifications.requestPermissionsAsync();
            finalStatus = status;
        }
        if (finalStatus !== 'granted') {
            Alert.alert('Failed to get push token for push notification!');
            //showModalMessage(_message);            
            return;
        }
        //token = (await Notifications.getExpoPushTokenAsync()).data;
        token = await Notifications.getExpoPushTokenAsync({
            projectId: Constants.expoConfig.extra.eas.projectId,
        });

        if (Platform.OS === 'android') {
            Notifications.setNotificationChannelAsync('default', {
              name: 'myshott',
              importance: Notifications.AndroidImportance.MAX,
              vibrationPattern: [0, 250, 250, 250],
              lightColor: '#FF231F7C',
            });
        }

        // 여기서 백엔드 서버에 토큰을 저장해야 합니다.
        const ret = await RegisterPushNotiToDB(user_id, token.data);
        //console.log(ret);
        if( ret.data > 0 ) {
            //Alert.alert('토큰 저장 성공', token);
            result = true;
        }
    } catch (e) {
        console.log(e);
   
    }
    return result;
}
