import { Platform } from 'react-native';
import { useEnv } from './AppContext';
import { useUserItem } from './UserItemContext';
import { RegisterForPushNotifications } from './PushNotification.js';
import * as Device from 'expo-device';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { SetDeviceInfo } from './api';
import platform from 'platform';

export const useLoginProc = () => {
    const { ENV, setEnv } = useEnv();
    const { ReadUserItem } = useUserItem();

    const saveValue = async (key, value) => {
      try {
        const stringValue = JSON.stringify(value); // 값 변환
        await AsyncStorage.setItem(key, stringValue);
        //console.log(`${key} saved successfully`);
      } catch (error) {
        console.error(`Error saving ${key}`, error);
      }
    };    

    const getValue = async (key) => {
      try {
        const stringValue = await AsyncStorage.getItem(key);
        const value = JSON.parse(stringValue); // 문자열에서 원래 타입으로 변환
        //console.log(`${key}:`, value);
        return value;
      } catch (error) {
        console.error(`Error reading ${key}`, error);
        return null;
      }
    };   

    const loginProc = async (newENV) => {
        try {
            setEnv(prevEnv => ({
              ...prevEnv,
              ...newENV
            }));            
            if( newENV.user_id) {
              const ret = await ReadUserItem(newENV.user_id);
              if (ret.success) {
                let device_info = '';
                if(Platform.OS === 'web') {
                  const browserInfo = platform.parse(navigator.userAgent);
                  device_info = `W ${browserInfo.name}.${browserInfo.version} ${browserInfo.os}`;
                } else {
                  await RegisterForPushNotifications(newENV.user_id);

                  device_info = `M ${Device.osName}.${Device.osVersion} ${Device.brand}.${Device.modelName}`;
                }
                await SetDeviceInfo(device_info, newENV.user_id);
              } else {
                console.log('LoginProc2 error(failed ReadUserItem): ', ret.error);
                //Alert.alert('LoginProc error', ret.error)
              }
            }      
          } catch(e) {
            console.log('LoginProc2 error', e);
            //Alert.alert('LoginProc error', e)
          }        
    }

    return { loginProc };
};