import React, {useState, useEffect} from 'react';
import { StyleSheet, Text, View, Pressable , TouchableOpacity, Image, Dimensions  } from 'react-native';
import GSS from '../GlobalStyleSheet2';
import CloseHeader from '../CloseHeader';
import ConfirmImage from '../ConfirmImage';
import ProgressButton from '../ProgressButton';
import { GetNotifyError, GetErrorType, SetNotifyError} from '../api';
import nextImage from '../../assets/next.png';
import nextBImage from '../../assets/nextB.png';
import prevImage from '../../assets/prev.png';
import prevBImage from '../../assets/prevB.png';
import { ScrollView } from 'react-native-gesture-handler';

const CheckNotifyError = ({modalID, closeModal, user_item}) => {
    const [errors, setErrors] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0); // 현재 메시지 인덱스

    const CloseButton = () => {
        closeModal(modalID);
    };     

    useEffect(() => {
        (async () => {
            const ret2 = await GetErrorType();
            if( ret2.success) {
                const ret = await GetNotifyError(user_item.user_item_id);
                if( ret.success) {
                    const items = [];
                    for(const item of ret.data) {
                        const item2 = ret2.data.find(item2 => item2.error_type === item.error_type)
                        items.push({
                            error_id : item.error_id,
                            error : item2.error_type_desc,
                            error_desc : item.error_desc,
                            check_completed : item.check_completed
                        });                     
                    }
                    setErrors(items);     
                }
            }              
        })(); 
    }, []);   
    
    const goToPrevMessage = () => {
      setCurrentIndex(currentIndex - 1);
    };

    const goToNextMessage = () => {
      setCurrentIndex(currentIndex + 1);
    };   

    const checkedError = async () => {
        console.log(errors);
        console.log('checkedError : ', errors[currentIndex].error_id);
        try {
            const ret = await SetNotifyError(errors[currentIndex].error_id);
            if( ret.success ) {
                const newItems = [ ... errors];
                newItems[currentIndex].check_completed = 1;
                setErrors(newItems);
            }
        } catch(e) {
            console.log(e);
        }
    }

    const windowWidth = Dimensions.get('window').width;
    return (
        <View style={GSS.mainContainer}>
            <View style={{height:30}}>
                <CloseHeader
                    closeAction={CloseButton}
                /> 
            </View>
            <View style={[GSS.emptyViewBox, {alignItems:'center'}]}>
                <Text style={GSS.mainInfoTextB}>파티에 다음과 같은 문제가 있는지 확인해 주세요</Text>
            </View>
            <View style={{ flex:1, flexDirection: 'row', justifyContent:'space-between', alignItems:'center', }}>
                <TouchableOpacity
                    disabled={currentIndex === 0}
                    onPress={goToPrevMessage}
                >
                    <Image
                        source={currentIndex === 0 ? prevImage : prevBImage}
                        style={{height:30, width:30}}
                    />
                </TouchableOpacity>
                {errors.length > 0 && (
                <ScrollView style={{flex:1}}>
                    <View style={[GSS.mainViewBox]}>
                        <Text style={[GSS.emphasisText, {fontSize:16} ]}>{errors[currentIndex].error}</Text>
                    </View>
                    {/* <View style={[GSS.mainViewBox]}>
                        <Text style={GSS.mainDefaultText}>{errors[currentIndex].error_desc}</Text>
                    </View>                     */}
                    <View style={[GSS.mainViewBox, styles.confirmView]}>
                        <Text style={[GSS.mainInfoText, {margin:3, width:windowWidth*0.5, }]}>위 문제를 확인 하셨나요?</Text>
                        <ConfirmImage
                            isActive={errors[currentIndex].check_completed}
                            onPress={() => checkedError(currentIndex)}
                            noDisabled={true}
                        /> 
                    </View>                   
                </ScrollView>

                )}
                <TouchableOpacity
                    disabled={currentIndex === errors.length - 1}
                    onPress={goToNextMessage}
                >
                    <Image
                        source={currentIndex === errors.length - 1 ? nextImage : nextBImage}
                        style={{height:30, width:30}}
                    />
                </TouchableOpacity>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    confirmView: {
        flex: 1,
        flexDirection: 'row',
    },    
});

export default CheckNotifyError;